import { briefingApi } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useSubjects(type, groupCodes = [], filter = {}) {
  return useQuery({
    queryKey: ['subjects', type, groupCodes, filter],
    queryFn: () => {
      log('Read', 'Subjects', { type, groupCodes, filter });

      const searchParams = new URLSearchParams([
        ['type', type],
        ...Object.entries(filter).map(([key, value]) => [key, value]),
        ...groupCodes.map((code) => ['groupCodes', code]),
      ]);
      return briefingApi.get('subjects', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
