import { useDeleteSharedMissingPerson } from '../useDeleteSharedMissingPerson';
import { useSharedMissingPerson } from '../useSharedMissingPerson';
import { useUpsertSharedMissingPerson } from '../useUpsertSharedMissingPerson';
import { SharedMissingPersonEditorDialog } from './SharedMissingPersonEditorDialog';

export function SharedMissingPersonEditor({ ids, isOpen, onClose }) {
  const { data: share } = useSharedMissingPerson(ids?.personId, {
    personId: ids?.personId,
    groupCodes: [],
  });

  const { mutate: upsertMissingPerson } = useUpsertSharedMissingPerson();
  const { mutate: deleteMissingPerson } = useDeleteSharedMissingPerson();

  function handleClose(values) {
    if (values) {
      upsertMissingPerson(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteMissingPerson(ids?.code);

    onClose();
  }

  return (
    <SharedMissingPersonEditorDialog
      key={share}
      share={share}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
    />
  );
}
