import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  QuestionMark as QuestionMarkIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import { grey, orange, red, yellow } from '@mui/material/colors';

export function getRiskProps(risk) {
  switch (risk) {
    case 'HI':
      return {
        label: 'High',
        color: red[500],
        Icon: KeyboardArrowUpIcon,
      };
    case 'MED':
      return {
        label: 'Medium',
        color: orange[500],
        Icon: RemoveIcon,
      };
    case 'LOW':
      return {
        label: 'Low',
        color: yellow[500],
        Icon: KeyboardArrowDownIcon,
      };
    default:
      return {
        label: 'Unknown',
        color: grey[500],
        Icon: QuestionMarkIcon,
      };
  }
}
