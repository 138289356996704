import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useInsertSubject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ keys, ...json }) => {
      const searchParams = new URLSearchParams(
        keys.map((key) => ['keys', key]),
      );

      briefingApi.post('subjects', { json, searchParams }).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['subjects'] }),
  });
}
