import { briefingApi } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useWatches(groupCodes = []) {
  return useQuery({
    queryKey: ['watches', groupCodes],
    queryFn: () => {
      log('Read', 'Watches', { groupCodes });

      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );
      return briefingApi.get('watches', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
