import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSharedMissingPerson(personId, defaultValue) {
  return useQuery({
    queryKey: ['sharedMissingPersons', personId, defaultValue],
    queryFn: async () => {
      const existingShare = await briefingApi
        .get(`shared-missing-persons/${personId}`)
        .json();

      if (existingShare?.code) {
        return existingShare;
      } else {
        return defaultValue;
      }
    },
    enabled: !!personId,
  });
}
