import { CustomTooltip, Parameters } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber, blue } from '@mui/material/colors';
import { useAtom } from 'jotai';
import { Fragment, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useLocationOccupancyChanges } from './useLocationOccupancyChanges';
import { formatLabel, stateAtom } from './utils';
import { downloadCSV, getFilenameForDownload } from '@/utils';

export function TimelineInLocations() {
  useDocumentTitle('IR3 | Timeline in Location');
  const [{ query, parameters }, setState] = useAtom(stateAtom);
  const [hiddenLines, setHiddenLines] = useState([]);
  const { data, cancel, isLoading, isFetching } =
    useLocationOccupancyChanges(query);
  const changes = useMemo(
    () =>
      data
        .map(({ time, ...measures }) => ({
          time: time.getTime(),
          ...measures,
        }))
        .sort((a, b) => a.time - b.time),
    [data],
  );

  function handleFetch(event, query) {
    setState((state) => ({ ...state, query }));
  }

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleParametersChange(parameters) {
    setState((state) => ({ ...state, parameters }));
  }

  const handleLegendClick = (type) => () => {
    setHiddenLines((lines) =>
      lines.includes(type)
        ? lines.filter((line) => line !== type)
        : [...lines, type],
    );
  };

  function handleDownloadClick() {
    const headers = [
      { label: 'Time', key: 'time' },
      { label: 'Home Vehicles', key: 'residents' },
      { label: 'Visiting Vehicles', key: 'visitors' },
    ];
    const filename = getFilenameForDownload(
      'timeline-in-locations',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(data, filename, headers);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
      }}
    >
      <Parameters
        collection="intersections"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading || isFetching}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        location
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          pt: 1.5,
          pr: 1,
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ alignItems: 'center' }}
              onClick={handleLegendClick('residents')}
            >
              <Avatar
                sx={{
                  width: 12,
                  height: 12,
                  mr: 0.5,
                  bgcolor: !hiddenLines.includes('residents') && blue[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Home Vehicles</Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ alignItems: 'center' }}
              onClick={handleLegendClick('visitors')}
            >
              <Avatar
                sx={{
                  width: 12,
                  height: 12,
                  mr: 0.5,
                  bgcolor: !hiddenLines.includes('visitors') && amber[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Visiting Vehicles</Typography>
            </Stack>
            <Tooltip title="Download data">
              <Box component="span">
                <IconButton
                  disabled={data.length === 0}
                  onClick={handleDownloadClick}
                >
                  <GetAppIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Stack
              direction="row"
              sx={{ pb: 1, justifyContent: 'space-between' }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                color="textSecondary"
              >
                Occupants
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Total number of vehicles in the selected locations
              </Typography>
            </Stack>
            <ResponsiveContainer width="99%" height={400}>
              <AreaChart
                data={changes}
                margin={{ top: 8, right: 16, left: 0, bottom: 32 }}
              >
                <XAxis
                  dataKey="time"
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={formatLabel}
                >
                  <Label value="Time" position="bottom" offset={36} />
                </XAxis>
                <YAxis />
                <ChartTooltip
                  content={
                    <CustomTooltip
                      unit="vehicles"
                      labelFormatter={formatLabel}
                    />
                  }
                />
                <Area
                  type="stepAfter"
                  dataKey="residents"
                  name="Home Vehicles"
                  dot={false}
                  stroke={blue[500]}
                  fill={blue[500]}
                  strokeWidth={1}
                  stackId="1"
                  hide={hiddenLines.includes('residents')}
                  isAnimationActive={false}
                  connectNulls
                />
                <Area
                  type="stepAfter"
                  dataKey="visitors"
                  name="Visiting Vehicles"
                  dot={false}
                  stroke={amber[500]}
                  fill={amber[500]}
                  strokeWidth={1}
                  stackId="1"
                  hide={hiddenLines.includes('visitors')}
                  isAnimationActive={false}
                  connectNulls
                />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
}
