import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteWatch() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code) => {
      if (code) {
        return briefingApi.delete(`watches/${code}`);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['watches'] }),
  });
}
