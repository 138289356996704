import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNichePeople({
  classificatonCodes = [],
  risk,
  flagCodes = [],
  groupType,
  groupCodes = [],
}) {
  return useQuery({
    queryKey: [
      'nichePeople',
      classificatonCodes,
      risk,
      flagCodes,
      groupType,
      groupCodes,
    ],
    queryFn: () => {
      const searchParams = new URLSearchParams(
        [
          ...classificatonCodes.map((code) => ['classificationCodes', code]),
          risk && ['risk', risk],
          ...flagCodes.map((code) => ['flagCodes', code]),
          groupType && ['groupType', groupType],
          ...groupCodes.map((code) => ['groupCodes', code]),
        ].filter(Boolean),
      );

      return briefingApi.get('niche/people', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
