import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useToggleSubjectKey() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code, key }) => {
      briefingApi.patch(`subjects/${code}/${key}/toggle`).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['subjects'] }),
  });
}
