import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNichePerson(
  id,
  { image = false, addresses = false, type } = {},
) {
  return useQuery({
    queryKey: ['nichePeople', id, image, addresses, type],
    queryFn: () => {
      const searchParams = new URLSearchParams({
        image,
        addresses,
      });

      return briefingApi
        .get(`niche/people/${id}${type ? `/${type}` : ''}`, { searchParams })
        .json();
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 60,
  });
}
