import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteSharedMissingPerson() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code) => {
      if (code) {
        return briefingApi.delete(`shared-missing-persons/${code}`).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['sharedMissingPersons'] }),
  });
}
