import { useLocationOptions, useWatches } from '@/hooks';
import { Add as AddIcon } from '@mui/icons-material';
import { Avatar, Box, IconButton, Stack, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { PrisonerEditor } from './PrisonerEditor';

const enRouteColumnsFn = (hospitalLookup) => [
  {
    header: 'Custody Unit',
    accessorKey: 'custodyUnit',
  },
  {
    header: 'Occurrence',
    accessorKey: 'occurrenceNumber',
  },
  {
    header: 'Hospital',
    id: 'hospitalCode',
    accessorFn: ({ hospitalCode }) =>
      hospitalLookup?.[hospitalCode]?.name ?? hospitalCode,
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Offence',
    accessorKey: 'offence',
  },
  {
    header: 'Arresting Officer',
    accessorKey: 'arrestingOfficerCode',
  },
  {
    header: 'Investigating Officer',
    accessorKey: 'investigatingOfficerCode',
  },
  {
    header: 'OIC',
    accessorKey: 'officerInCaseCode',
  },
  {
    header: 'OIC Team',
    accessorKey: 'owningTeam',
  },
];

export function PrisonerWatches({ groupCodes }) {
  const [editEnrouteCode, setEditEnrouteCode] = useState(null);
  const {
    data: enRoutePrisoners,
    isFetching: prisonersFetching,
    isLoading: prisonersLoading,
  } = useWatches(groupCodes);
  const hospitalLookup = useLocationOptions();

  // const { data: custodyRecords } = useCustodyRecords();
  // const enRoutePrisonerCodes = enRoutePrisoners.map(
  //   (prisoner) => prisoner.personCode,
  // );

  // // get all the custody records where observationLevelCode not LV3 or LV4:
  // //observationLevelCode:LV1, LV2, LV3, LV4, etc
  // const prisonersWithoutObservation = custodyRecords?.filter(
  //   (custody) =>
  //     custody.observationLevelCode !== 'LV3' &&
  //     custody.observationLevelCode !== 'LV4',
  // );

  // // get all the person records in niche for codes in ir3
  // const {
  //   data: enRoutePrisonersInNiche,
  //   isLoading: prisonersLoading,
  //   isFetching: prisonersFetching,
  // } = useNicheNominals({
  //   ids: enRoutePrisonerCodes,
  //   groupCodes,
  // });

  // // store enroute prisoners name with person id as a key
  // const niheEnroutePrisonersName = {};
  // if (enRoutePrisonersInNiche && enRoutePrisonersInNiche.length > 0) {
  //   enRoutePrisonersInNiche.map((prisoner) => {
  //     const { nominalId, firstName, surname } = prisoner.person;
  //     niheEnroutePrisonersName[nominalId] = `${firstName} ${surname}`;
  //   });
  // }

  // // get the enroutes already in custody
  // const enRoutesExistsInCustody = enRoutePrisonersInNiche?.filter((enroute) =>
  //   prisonersWithoutObservation?.find(
  //     (custody) => enroute.person.id === custody.personId,
  //   ),
  // );

  // // exclude enroutes already in custody without observationCode LV3 or LV4
  // const filteredEnRoutes = enRoutePrisoners.filter((enRoute) => {
  //   return !enRoutesExistsInCustody?.find(
  //     (niche) => niche.person.nominalId === enRoute.personCode,
  //   );
  // });

  // // add/update names
  // filteredEnRoutes.map((prisoner) => {
  //   if (prisoner.personCode) {
  //     prisoner.name = niheEnroutePrisonersName[prisoner.personCode];
  //   }
  //   return prisoner;
  // });

  const enRouteColumns = useMemo(
    () => enRouteColumnsFn(hospitalLookup),
    [hospitalLookup],
  );

  function handleEnRouteAddClick() {
    setEditEnrouteCode('new');
  }

  function onEditorClose() {
    setEditEnrouteCode(null);
  }

  const handleEnrouteRowClick = (code) => () => {
    setEditEnrouteCode(code);
  };

  return (
    <Stack spacing={1}>
      <MaterialReactTable
        muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
        defaultColumn={{ size: 0, enableEditing: false }}
        columns={enRouteColumns}
        data={enRoutePrisoners}
        enableGrouping
        enableBottomToolbar={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePagination={false}
        state={{ isLoading: prisonersLoading || prisonersFetching }}
        initialState={{
          density: 'compact',
          expanded: true,
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Stack
              width={1}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box>
                <Tooltip title="Add En Route Prisoner" placement="left">
                  <IconButton size="small" onClick={handleEnRouteAddClick}>
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        bgcolor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                        m: 0.25,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          );
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: handleEnrouteRowClick(row.original?.code),
          sx: {
            cursor: 'pointer',
            '&:last-child td, &:last-child th': { border: 0 },
          },
        })}
        muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
      />
      <PrisonerEditor
        code={editEnrouteCode}
        isOpen={Boolean(editEnrouteCode)}
        onClose={onEditorClose}
        groupCodes={groupCodes}
      />
    </Stack>
  );
}
