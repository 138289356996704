import {
  AccountBox as AccountBoxIcon,
  Article as ArticleIcon,
  UTurnLeft as UTurnLeftIcon,
} from '@mui/icons-material';

export function getFlagProps(code) {
  switch (code) {
    case 'PRC':
      return {
        label: 'Prison recall',
        Icon: UTurnLeftIcon,
      };
    case 'WAA':
      return {
        label: 'Outstanding warrant',
        Icon: ArticleIcon,
      };
    default:
      return {
        label: 'Suspect',
        Icon: AccountBoxIcon,
      };
  }
}
