import { LoadingDialog } from '@/components/controls';
import { useDeleteLocation, useLocation, useUpsertLocation } from '@/hooks';
import { SceneEditorDialog } from './SceneEditorDialog';

export function SceneEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: scene } = useLocation(code, {
    name: '',
    type: 'SCENE',
    boundary: null,
    groupCodes,
    attributes: {
      incidentCode: '',
      occurrenceNumber: '',
      requiredAttendance: 0,
    },
  });
  const { mutate: upsertLocation } = useUpsertLocation();
  const { mutate: deleteLocation } = useDeleteLocation();

  function handleClose(values) {
    if (values) {
      upsertLocation(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteLocation(code);

    onClose();
  }

  return scene ? (
    <SceneEditorDialog
      scene={scene}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
      groupCodes={groupCodes}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}
