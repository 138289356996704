import { hasNoValues } from '@/utils';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

function JSONTree({ data }) {
  function getType(data) {
    if (Array.isArray(data)) {
      return 'array';
    }

    if (data instanceof Date) {
      return 'date';
    }

    if (data === null) {
      return 'null';
    }

    if (data === undefined) {
      return 'undefined';
    }

    return typeof data;
  }

  switch (getType(data)) {
    case 'object':
      return (
        <Stack spacing={1}>
          {Object.entries(data)
            .filter(
              ([key, value]) => !['_id'].includes(key) && !hasNoValues(value),
            )
            .map(([key, value]) => (
              <Stack
                key={key}
                spacing={1}
                direction="row"
                sx={{ justifyContent: 'space-between' }}
              >
                <Typography
                  noWrap
                  variant="caption"
                  color="textSecondary"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {key}
                </Typography>
                <JSONTree data={value} />
              </Stack>
            ))}
        </Stack>
      );
    case 'array':
      return (
        <Stack spacing={1}>
          {data.map((item, index) => (
            <JSONTree key={index} data={item} />
          ))}
        </Stack>
      );
    case 'date':
      return (
        <Typography
          noWrap
          variant="caption"
          sx={{ fontWeight: 'bold', textAlign: 'right' }}
        >
          {format(data, 'dd/MM/yyyy HH:mm')}
        </Typography>
      );
    default:
      return (
        <Typography
          noWrap
          variant="caption"
          sx={{ fontWeight: 'bold', textAlign: 'right' }}
        >
          {data}
        </Typography>
      );
  }
}

export function AuditParameters({ parameters }) {
  return (
    parameters && (
      <Stack sx={{ alignItems: 'flex-start' }}>
        <JSONTree data={parameters} />
      </Stack>
    )
  );
}
