import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUrgentAction(code, defaultValue) {
  return useQuery({
    queryKey: ['urgentActions', code, defaultValue],
    queryFn: () => {
      if (code === 'new') {
        return defaultValue;
      }

      return briefingApi.get(`urgent-actions/${code}`).json();
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60,
  });
}
