import { useOptionLookup } from '@/hooks';
import { isEmpty } from '@/utils';
import { useRfidCardCategory } from '@/utils/config';
import {
  Autocomplete,
  Chip,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useController } from 'react-hook-form';

export function RfidCardsController({ control, name, label, disabled, rules }) {
  const categories = useOptionLookup('tripClassification');
  const {
    field: { value, disabled: isDisabled, onChange, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: [],
    disabled,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  function handleAvatarClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(event, value) {
    const [reference, ...existingValues] = value.slice().reverse();

    if (value.length === 0 || typeof reference === 'object') {
      onChange(value);
    } else {
      onChange([
        ...existingValues.reverse(),
        { category: 'Business', reference },
      ]);
    }
  }

  function renderInput(params) {
    return (
      <TextField
        inputRef={ref}
        label={label}
        helperText={error?.message}
        error={invalid}
        {...params}
      />
    );
  }

  function renderTags(value, getTagProps) {
    return value.map((option, index) => {
      const { key, ...props } = getTagProps({ index });

      return (
        <Chip
          key={key}
          data-reference={option.reference}
          label={option.reference}
          {...props}
          icon={
            !isEmpty(categories) && useRfidCardCategory ? (
              <Chip
                color="secondary"
                label={categories[option.category]}
                size="small"
              />
            ) : undefined
          }
          onClick={
            !isEmpty(categories) && useRfidCardCategory && !disabled
              ? handleAvatarClick
              : undefined
          }
        />
      );
    });
  }

  const handleCategoryClick = (category) => () => {
    onChange(
      value.map((chip) =>
        chip.reference === anchorEl.dataset.reference
          ? {
              category,
              reference: anchorEl.dataset.reference,
            }
          : chip,
      ),
    );
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        size="small"
        multiple
        freeSolo
        value={value || []}
        options={[]}
        disabled={isDisabled}
        onChange={handleChange}
        renderInput={renderInput}
        renderTags={renderTags}
      />
      {categories && (
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.entries(categories).map(([value, label]) => (
            <MenuItem key={value} onClick={handleCategoryClick(value)}>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
}
