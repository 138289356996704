import { ConfirmationDialog } from '@/components/dialogs';
import { useOptions } from '@/hooks';
import {
  rfidCardForm,
  useDallasKeys,
  useRfidCardCategory,
} from '@/utils/config';
import {
  Person as PersonIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TextFieldController } from '../../controls';

export function RfidCardForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  onRemove,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [removePerson, setRemovePerson] = useState(null);
  const { data: categories } = useOptions('tripClassification');
  const { data: rfidCardTypes } = useOptions('rfidCardType');
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  const handleRemoveClick = (person) => (event) => {
    event.preventDefault();

    setRemovePerson(person);
  };

  function handleRemove() {
    onRemove(
      removePerson._id,
      removePerson.rfidCards.filter(
        (card) => card.reference !== defaultValues.reference,
      ),
    );
    setRemovePerson(null);
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (rfidCardForm[fieldName]?.editable || !defaultValues?._id)
    );
  }

  return (
    <Fragment>
      <form id="rfid-card-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ p: 1 }}>
            {defaultValues?._id
              ? defaultValues.reference
              : `New ${useDallasKeys ? 'Dallas Key' : 'RFID Card'}`}
          </Typography>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="reference"
              label="Serial Number"
              control={control}
              disabled={isDisabled('reference')}
              rules={{ required: 'Required', maxLength: 20 }}
              parse={(value) =>
                value && !isNaN(parseInt(value, 16))
                  ? parseInt(value, 16).toString(16)
                  : ''
              }
            />
            {useRfidCardCategory && (
              <TextFieldController
                name="category"
                label="Category"
                control={control}
                options={categories}
                disabled={isDisabled('category')}
              />
            )}
            <TextFieldController
              name="type"
              label="Type"
              control={control}
              options={rfidCardTypes}
              disabled={isDisabled('type')}
            />
            <TextFieldController
              name="label"
              label="Label"
              control={control}
              disabled={isDisabled('label')}
              rules={{ maxLength: 50 }}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Assigned People
            </Typography>
          </Divider>
          {defaultValues?.people?.length > 0 ? (
            defaultValues.people.map((person) => (
              <Card
                key={person.code}
                variant="outlined"
                sx={{ width: 320, mr: 1, mb: 1 }}
              >
                <CardActionArea
                  component={Link}
                  to={`/resources/people/${encodeURIComponent(person.code)}`}
                >
                  <CardHeader
                    avatar={
                      <Avatar src={person.picture}>
                        <PersonIcon />
                      </Avatar>
                    }
                    action={
                      <Tooltip title="Remove">
                        <IconButton
                          onClick={handleRemoveClick(person)}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    title={`${person.forenames} ${person.surname}`}
                    subheader={person.collarNumber}
                  />
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              There is no one assigned to this card
            </Typography>
          )}
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={toggleDeleteDialog}
              disabled={!watch('_id')}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.reference}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
      <ConfirmationDialog
        action="Remove"
        open={!!removePerson}
        itemId={
          removePerson
            ? [removePerson.forenames, removePerson.surname].join(' ') ||
              removePerson.collarNumber ||
              removePerson.code
            : ''
        }
        onOk={handleRemove}
        onCancel={() => setRemovePerson(null)}
      />
    </Fragment>
  );
}
