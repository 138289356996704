import { DetailsPanel } from '@/components/controls';
import { useGroupOptions, useNichePerson } from '@/hooks';
import {
  Cake as CakeIcon,
  Checklist as ChecklistIcon,
  Map as MapIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useMemo } from 'react';
import { useSharedMissingPerson } from '../useSharedMissingPerson';
import { GenderIcon } from './GenderIcon';
import { RiskAvatar } from './RiskAvatar';

export function MissingPerson({ personId, onShowMapClick, onShareClick }) {
  const { data: missingPerson } = useNichePerson(personId, {
    addresses: true,
    image: true,
    type: 'missing',
  });
  const groupLookup = useGroupOptions();
  const { data: share } = useSharedMissingPerson(personId, {
    personId,
    groupCodes: [],
  });
  const groupsEntries = useMemo(
    () =>
      Object.entries(
        (missingPerson?.groupCodes ?? [])
          .filter((code) => groupLookup[code])
          .map((code) => groupLookup[code])
          .reduce(
            (acc, group) => ({
              ...acc,
              [group.type]: [...(acc[group.type] ?? []), group.name],
            }),
            {},
          ),
      ).map(([type, names]) => ({
        label: type,
        value: names.join(', '),
      })),
    [missingPerson, groupLookup],
  );

  return (
    <Card variant="outlined" sx={{ minWidth: 240 }}>
      <CardHeader
        avatar={<RiskAvatar risk={missingPerson?.risk} />}
        action={
          <Fragment>
            <Tooltip title={share?.code ? 'Edit share details' : 'Share'}>
              <IconButton
                size="small"
                onClick={onShareClick(share?.code ?? 'new', personId)}
              >
                <ShareIcon
                  color={share?.code ? 'primary' : 'inherit'}
                  sx={{ fontSize: 'inherit' }}
                />
              </IconButton>
            </Tooltip>
            {missingPerson?.addresses?.length > 0 && (
              <Tooltip title="Show map">
                <IconButton size="small" onClick={onShowMapClick}>
                  <MapIcon sx={{ fontSize: 'inherit' }} />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
        title={[missingPerson?.forenames, missingPerson?.surname]
          .join(' ')
          .toLowerCase()}
        sx={{ textTransform: 'capitalize' }}
      />
      {missingPerson?.imageSrc ? (
        <CardMedia
          component="img"
          sx={{ minHeight: 240, width: '100%' }}
          image={missingPerson.imageSrc}
          alt="Photo of missing person"
        />
      ) : (
        <Box
          sx={(theme) => ({
            height: 240,
            width: '100%',
            bgcolor: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.300',
          })}
        />
      )}
      <Stack spacing={1} sx={{ p: 1 }}>
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
          <Typography
            variant="body3"
            color="textSecondary"
            sx={{ flexGrow: 1 }}
          >
            {missingPerson?.occurrenceNumber}
          </Typography>
          <GenderIcon gender={missingPerson?.gender} />
          <CakeIcon sx={{ fontSize: 12 }} />
          <Typography variant="subtitle2">
            {missingPerson?.dateOfBirth &&
              format(missingPerson.dateOfBirth, 'dd/MM/yyyy')}
          </Typography>
        </Stack>
        {share?.code && (
          <Typography variant="body2" sx={{ p: 0.5 }}>
            {share?.reason}
          </Typography>
        )}
        <DetailsPanel
          entries={[
            {
              label: 'Reported missing',
              value: missingPerson?.reportedTime
                ? format(missingPerson.reportedTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
            {
              label: 'Last seen',
              value: missingPerson?.lastSeenTime
                ? format(missingPerson.lastSeenTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
            {
              label: 'Last log entry',
              value: missingPerson?.lastLogEntryTime
                ? format(missingPerson.lastLogEntryTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
            {
              label: 'Next review due',
              value: missingPerson?.nextReviewTime
                ? format(missingPerson.nextReviewTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
          ]}
        />
        <DetailsPanel
          title="Owned by"
          entries={[
            {
              label: 'OIC',
              value: missingPerson?.officerInCase,
            },
            ...groupsEntries,
          ]}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 1, justifyContent: 'flex-end' }}
        >
          <Tooltip
            title={
              missingPerson?.returnInterviewCompleted &&
              missingPerson?.supervisorReturnInterviewCompleted
                ? `Interviewed by OIC and supervisor`
                : missingPerson?.returnInterviewCompleted
                  ? `Interviewed by OIC`
                  : missingPerson?.supervisorReturnInterviewCompleted
                    ? `Interviewed by supervisor`
                    : 'Not interviewed'
            }
            placement="top-end"
            arrow
          >
            <QuestionAnswerIcon
              color={
                missingPerson?.returnInterviewCompleted &&
                missingPerson?.supervisorReturnInterviewCompleted
                  ? 'success'
                  : !missingPerson?.returnInterviewCompleted &&
                      !missingPerson?.supervisorReturnInterviewCompleted
                    ? 'error'
                    : 'warning'
              }
              sx={{ fontSize: 16 }}
            />
          </Tooltip>
          <Tooltip
            title={
              missingPerson?.supervisorQuestionSetCompleted
                ? `Question set completed`
                : 'Question set outstanding'
            }
            placement="top-end"
            arrow
          >
            <ChecklistIcon
              color={
                missingPerson?.supervisorQuestionSetCompleted
                  ? 'success'
                  : 'error'
              }
              sx={{ fontSize: 16 }}
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
}
