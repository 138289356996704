import { LocationMapDialog } from '@/components/controls';
import {
  useInsertSubject,
  useNichePeople,
  useNichePerson,
  useSubjects,
  useToggleSubjectKey,
} from '@/hooks';
import { getGroupType } from '@/utils';
import { Done as DoneIcon } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import {
  alpha,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { PrisonRecall } from './PrisonRecall';
import { Suspect } from './Suspect';
import { Warrant } from './Warrant';

export function Subjects({ groupCodes, onComplete }) {
  const { data: suspects } = useNichePeople({
    classificatonCodes: ['SSI', 'SUS', 'WOW', 'WAN'],
    groupType: getGroupType('SECTOR'),
    groupCodes,
  });
  const { data: prisonRecalls } = useNichePeople({
    flagCodes: ['PRC'],
    groupType: getGroupType('SECTOR'),
    groupCodes,
  });
  const { data: outstandingWarrants } = useNichePeople({
    flagCodes: ['WAA', 'WAB', 'WAC'],
    groupType: getGroupType('SECTOR'),
    groupCodes,
  });
  const { data: highPrioritySuspects } = useSubjects('SUSPECT', groupCodes, {
    highPriority: true,
  });
  const outstandingSuspects = useMemo(
    () =>
      suspects.filter(
        (suspect) =>
          !(highPrioritySuspects ?? []).some(
            (highPrioritySuspect) =>
              highPrioritySuspect.personId === suspect.id,
          ),
      ),
    [suspects, highPrioritySuspects],
  );
  const { mutate: insertSubject } = useInsertSubject();
  const { mutate: toggleSubjectKey } = useToggleSubjectKey();
  const { width, ref } = useResizeDetector();
  const highPriorityColumns = useMemo(
    () => Math.floor(width / 328) || 1,
    [width],
  );
  const lowPriorityColumns = useMemo(
    () => Math.floor(width / 248) || 1,
    [width],
  );
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const { data: selectedPerson } = useNichePerson(selectedPersonId, {
    image: true,
    addresses: true,
    type: 'suspect',
  });

  const handleShowMapClick = (id) => () => {
    setSelectedPersonId(id);
  };

  function handleMapClose() {
    setSelectedPersonId(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  const handleOptionClick = (type, personId, code, key) => () => {
    if (code) {
      toggleSubjectKey({ code, key });
    } else {
      insertSubject({ type, personId, groupCodes, keys: [key] });
    }
  };

  return (
    <Box sx={{ px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={(theme) => ({
          position: 'sticky',
          top: 48,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          zIndex: 700,
          pl: 1,
          py: 1,
        })}
      >
        <Typography variant="h6">Suspects, Recalls & Warrants</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }} ref={ref}>
        {highPrioritySuspects?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              High Priority Suspects
            </Typography>
            <Masonry spacing={1} columns={highPriorityColumns}>
              {highPrioritySuspects.map(({ personId }) => (
                <Suspect
                  key={personId}
                  personId={personId}
                  onOptionClick={handleOptionClick}
                  onShowMapClick={handleShowMapClick(personId)}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
        {outstandingSuspects.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              Outstanding Suspects
            </Typography>
            <Masonry spacing={1} columns={lowPriorityColumns}>
              {outstandingSuspects.map(({ id }) => (
                <Suspect
                  key={id}
                  personId={id}
                  onOptionClick={handleOptionClick}
                  onShowMapClick={handleShowMapClick(id)}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
        {prisonRecalls?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              Prison Recalls
            </Typography>
            <Masonry spacing={1} columns={lowPriorityColumns}>
              {prisonRecalls.map(({ id }) => (
                <PrisonRecall
                  key={id}
                  personId={id}
                  onOptionFieldClick={handleOptionClick}
                  onShowLocationClick={handleShowMapClick}
                  groupCodes={groupCodes}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
        {outstandingWarrants?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              Outstanding Warrants
            </Typography>
            <Masonry spacing={1} columns={highPriorityColumns}>
              {outstandingWarrants.map(({ id }) => (
                <Warrant
                  key={id}
                  personId={id}
                  onOptionClick={handleOptionClick}
                  onShowMapClick={handleShowMapClick(id)}
                  groupCodes={groupCodes}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
      </Stack>
      <LocationMapDialog
        point={selectedPerson?.addresses?.[0].point}
        title={`${selectedPerson?.forenames} ${selectedPerson?.surname} home address`}
        description={selectedPerson?.addresses?.[0]?.label}
        isOpen={Boolean(selectedPersonId)}
        onClose={handleMapClose}
      />
    </Box>
  );
}
