import { CaptionedImage } from '@/components/controls';
import { useGroups, useNicheNominal, useUser } from '@/hooks';
import { listGroupLabels } from '@/utils';
import {
  Link as LinkIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

export function Notice({ notice, onSettingsClick }) {
  const { data: user } = useUser(notice.edited.username);
  const { data: nominal } = useNicheNominal(notice.nominalId, { image: true });
  const { data: groups } = useGroups({ types: ['SECTOR', 'TEAM'] });

  return (
    <Card variant="outlined">
      <Stack>
        <CardContent>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'space-between' }}
          >
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {user
                ? `${user?.displayName} - ${formatDistanceToNow(
                    new Date(notice.edited.time),
                  )} ago`
                : '-'}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {!!notice && !!groups
                ? `${listGroupLabels('Group', notice.groupCodes, groups)}`
                : ''}
            </Typography>
          </Stack>
          <Typography sx={{ whiteSpace: 'pre-wrap', p: 1 }} gutterBottom>
            {notice.message}
          </Typography>
          <Stack direction="row" spacing={1} sx={{ overflowX: 'auto' }}>
            {!!nominal && (
              <CaptionedImage
                imageSrc={nominal?.imageSrc}
                height={320}
                fallbackWidth={240}
                alt="Nominal"
                topCaption={notice.nominalId}
                bottomCaption={[nominal?.forenames, nominal?.surname]
                  .filter(Boolean)
                  .join(' ')
                  .toLowerCase()}
                bottomCaptionProps={{ sx: { textTransform: 'capitalize' } }}
              />
            )}
            {!!notice.imageSrc && (
              <img
                src={notice?.imageSrc}
                alt="Related"
                height={320}
                style={{ borderRadius: 4 }}
              />
            )}
          </Stack>
        </CardContent>
        <CardActions>
          {!!notice.linkHref && (
            <Tooltip title="Link">
              <IconButton size="small" href={notice.linkHref} target="_blank">
                <LinkIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Settings">
            <IconButton size="small" onClick={onSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Stack>
    </Card>
  );
}
