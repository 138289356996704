import { Avatar, Tooltip } from '@mui/material';
import { getFlagProps } from './utils';

export function FlagAvatar({ code }) {
  const { label, Icon } = getFlagProps(code);

  return (
    <Tooltip title={`${label}`}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          fontSize: 20,
        }}
      >
        <Icon sx={{ fontSize: 'inherit' }} />
      </Avatar>
    </Tooltip>
  );
}
