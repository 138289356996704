import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNicheOccurrence(
  number,
  {
    addresses = false,
    officers = false,
    people = false,
    vehicles = false,
  } = {},
) {
  return useQuery({
    queryKey: ['occurrences', number, addresses, officers, people, vehicles],
    queryFn: () => {
      const searchParams = new URLSearchParams({
        addresses,
        officers,
        people,
        vehicles,
      });

      return briefingApi
        .get(`niche/occurrences/${number}`, { searchParams })
        .json();
    },
    enabled: !!number,
    staleTime: 1000 * 60 * 60,
  });
}
