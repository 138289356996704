import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertUrgentAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      return json.code
        ? briefingApi.patch(`urgent-actions/${json.code}`, { json }).json()
        : briefingApi.post('urgent-actions', { json }).json();
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['urgentActions'] }),
  });
}
