import { locationsStateAtom } from '@/atoms';
import { NavLink, SearchBox, SortField } from '@/components/controls';
import {
  useDocumentTitle,
  useGroups,
  useLocations,
  useLocationTypes,
  useOptionLookup,
  useUserInfo,
} from '@/hooks';
import {
  downloadCSV,
  downloadGeoJSON,
  getFilenameForDownload,
  getLocations,
  isAuthorised,
  sortFn,
} from '@/utils';
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useAtom } from 'jotai';
import { useMemo, useRef } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { getLocationsAndHeaders } from './utils';

export function Locations() {
  useDocumentTitle('IR3 | Locations');
  const { data: locationTypes } = useLocationTypes();
  const { id, locationType = Object.keys(locationTypes)[0] } = useParams();
  const [{ searchText, sortBy, sortDesc, filter, showSettings }, setState] =
    useAtom(locationsStateAtom);
  const { data: locations } = useLocations({
    type: locationTypes[locationType]?.value,
  });
  const { data: groups } = useGroups();
  const typeOptions = useOptionLookup('groupType');
  const parentRef = useRef();
  const { data: userInfo } = useUserInfo();
  const canCreate = isAuthorised(userInfo?.authorisation, 'locations', true);

  const sortOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Code', value: 'code' },
  ];

  const filteredList = useMemo(() => {
    return locations
      .filter(
        (item) =>
          !filter.ancestor ||
          item.groupAncestorCodes.includes(filter.ancestor.value),
      )
      .filter(
        (item) =>
          item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
          searchText === '',
      )
      .sort(sortFn(sortBy, sortDesc));
  }, [filter, locations, searchText, sortBy, sortDesc]);
  const ancestors = useMemo(
    () =>
      groups
        .map((group) => ({
          label: group.name,
          value: group.code,
          type: typeOptions[group.type] ?? '',
        }))
        .sort(
          (a, b) =>
            a.type.localeCompare(b.type) || a.label.localeCompare(b.label),
        ),
    [groups, typeOptions],
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    setState((state) => ({
      ...state,
      searchText: event.target.value,
    }));
  }

  function handleSettingsToggle() {
    setState((state) => ({
      ...state,
      showSettings: !state.showSettings,
    }));
  }

  function handleSortByChange(event) {
    setState((state) => ({
      ...state,
      sortBy: event.target.value,
    }));
  }

  function handleSortToggle() {
    setState((state) => ({
      ...state,
      sortDesc: !state.sortDesc,
    }));
  }

  function handleAncestorFilterChange(event, option) {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        ancestor: option,
      },
    }));
  }

  function getFilteredDataForDownload(data) {
    const filteredData = data.filter((o1) =>
      filteredList.some((o2) => o1.code === o2.code),
    );

    return filteredData;
  }

  async function handleCsvClick() {
    const data = await getLocationsAndHeaders(
      locationTypes[locationType]?.value,
      typeOptions,
    );
    const filteredData = getFilteredDataForDownload(data.locations);
    const filename = getFilenameForDownload(`locations-${locationType}`, 'csv');
    downloadCSV(filteredData, filename, data.headers);
  }

  async function handleGeoJsonClick() {
    const data = await getLocations(locationTypes[locationType]?.value);
    const filteredData = getFilteredDataForDownload(data);
    const filename = getFilenameForDownload(
      `locations-${locationType}`,
      'json',
    );
    downloadGeoJSON(filteredData, filename);
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredList.length}/${locations.length}`}
              sx={{ flexGrow: 1 }}
            />
            <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
              <IconButton onClick={handleSettingsToggle} size="small">
                <SettingsIcon
                  fontSize="inherit"
                  color={showSettings ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
            {canCreate && (
              <Tooltip title="Add new">
                <IconButton
                  component={Link}
                  to={`/locations/${locationType}/new`}
                  size="small"
                >
                  <Avatar
                    sx={{
                      color: 'secondary.contrastText',
                      backgroundColor: 'secondary.main',
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    }}
                  >
                    <AddIcon fontSize="inherit" />
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <SortField
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortDesc={sortDesc}
                onSortDescToggle={handleSortToggle}
                options={sortOptions}
              />
            </Stack>
            <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <Autocomplete
                value={filter.ancestor}
                onChange={handleAncestorFilterChange}
                options={ancestors}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option?.label ?? option}
                sx={{ minWidth: 200 }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Group | Area" />
                )}
              />
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Export
                </Typography>
              </Divider>
              <Stack
                direction="row"
                // sx={{ p: 1 }}
                spacing={1}
                sx={{ justifyContent: 'center' }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleGeoJsonClick}
                >
                  GeoJSON
                </Button>
              </Stack>
            </Stack>
          </Collapse>
        </Box>
        <Divider />
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const item = filteredList[index];

              return (
                <ListItemButton
                  dense
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  component={NavLink}
                  to={`/locations/${locationType}/${encodeURIComponent(
                    item.code,
                  )}`}
                  selected={id === item.code}
                >
                  <ListItemAvatar>
                    <Avatar src={item.picture}>
                      <SvgIcon>
                        <path d={locationTypes[locationType]?.d} />
                      </SvgIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={item.code}
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
