import { mapStyleAtom } from '@/atoms';
import { MapBranding, MapButton } from '@/components/controls';
import { transformRequest } from '@/utils';
import { maxBounds, maxZoom, minZoom, styles } from '@/utils/config';
import {
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import { bbox } from '@turf/turf';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useCallback, useMemo, useState } from 'react';
import { Layer, Map, ScaleControl, Source } from 'react-map-gl/maplibre';

export function SceneMap({ scenes, onClick, mapRef }) {
  const [style, setStyle] = useAtom(mapStyleAtom);
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const featureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: scenes.map(({ boundary: geometry, ...properties }, id) => ({
        type: 'Feature',
        id,
        geometry,
        properties,
      })),
    }),
    [scenes],
  );

  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    initialViewState: {
      bounds:
        featureCollection.features.length > 0
          ? bbox(featureCollection)
          : maxBounds,
      fitBoundsOptions: { padding: 40 },
    },
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [cursor, setCursor] = useState('auto');

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    setStyle(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    mapRef.current.zoomIn();
  }

  function handleZoomOutClick() {
    mapRef.current.zoomOut();
  }

  function handleFitClick() {
    mapRef.current.fitBounds(bbox(featureCollection), {
      padding: 40,
      duration: 1000,
    });
  }

  function handleLoad() {
    setIsLoaded(true);
  }

  function handleError(errorEvent) {
    setIsLoaded(true);

    throw errorEvent.error;
  }

  const handleMouseEnter = useCallback(() => setCursor('pointer'), []);
  const handleMouseLeave = useCallback(() => setCursor('auto'), []);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{ position: 'relative', minWidth: 240, height: 640, flexShrink: 0 }}
    >
      <Map
        {...viewState}
        mapStyle={style}
        onMove={handleMove}
        transformRequest={transformRequest(style)}
        interactiveLayerIds={['boundary-layer']}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        cooperativeGestures
        cursor={cursor}
        ref={mapRef}
        onLoad={handleLoad}
        onError={handleError}
        style={{ borderRadius: 3 }}
      >
        <ScaleControl />
        <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
          <MapButton
            title="Zoom in"
            onClick={handleZoomInClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMaxZoom()
            }
          >
            <ZoomInIcon />
          </MapButton>
          <MapButton
            title="Zoom out"
            onClick={handleZoomOutClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMinZoom()
            }
          >
            <ZoomOutIcon />
          </MapButton>
          <MapButton
            title="Fit map"
            onClick={handleFitClick}
            disabled={!featureCollection}
          >
            <ZoomOutMapIcon />
          </MapButton>
          <MapButton title="Change map style" onClick={handleStyleClick}>
            <LayersIcon />
          </MapButton>
          <Menu
            anchorEl={styleMenuAnchorEl}
            open={Boolean(styleMenuAnchorEl)}
            onClose={handleStyleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuList
              disablePadding
              dense
              subheader={<ListSubheader disableSticky>Type</ListSubheader>}
            >
              {styles.map((layer) => (
                <MenuItem
                  key={layer.path}
                  onClick={handleStyleMenuClick(layer.path)}
                  selected={layer.path === style}
                >
                  {layer.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
        {featureCollection && (
          <Source id="boundary" type="geojson" data={featureCollection}>
            <Layer
              id="boundary-layer"
              type="fill"
              paint={{
                'fill-color': theme.palette.secondary.main,
                'fill-opacity': 0.4,
                'fill-outline-color': theme.palette.secondary.dark,
              }}
            />
          </Source>
        )}
        <MapBranding style={style} />
      </Map>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={!isLoaded}
      >
        <CircularProgress />
      </Backdrop>
    </Paper>
  );
}
