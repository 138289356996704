import { GroupCodesController } from '@/components/controls';
import { isOccurrenceNumber } from '@/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export function UrgentActionEditorDialog({
  urgentAction,
  isOpen,
  onClose,
  onDelete,
}) {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: urgentAction,
  });

  useEffect(() => {
    reset(urgentAction);
  }, [reset, urgentAction, isOpen]);

  function handleClose() {
    onClose();
  }

  return (
    <form id="urgent-action-editor-form" onSubmit={handleSubmit(onClose)}>
      <Dialog open={isOpen && !!urgentAction} onClose={handleClose}>
        <DialogTitle>{`${
          urgentAction.code ? 'Edit' : 'Add'
        } Urgent Action`}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText gutterBottom>
              Please fill in the form below to add an urgent action. If groups
              are not specified then the action will be visible to all teams and
              all sectors.
            </DialogContentText>
            <GroupCodesController
              label="Groups"
              control={control}
              name="groupCodes"
              types={['SECTOR', 'TEAM']}
            />
            <Controller
              name="occurrenceNumber"
              control={control}
              rules={{
                required: 'Required',
                validate: async (value) =>
                  !value || (await isOccurrenceNumber(value)),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Occurrence Number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Description"
                  multiline
                  rows={8}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!!urgentAction.code && (
            <Fragment>
              <Button color="error" onClick={onDelete}>
                Sign Off
              </Button>
              <Box sx={{ flexGrow: 1 }} />
            </Fragment>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="urgent-action-editor-form" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
