import { SearchBox } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  useDeleteUserQuery,
  useUserQueries,
  useUserQueryUserIds,
} from '@/hooks';
import { getComparator, stableSort } from '@/utils';
import {
  ArrowUpward as ArrowUpwardIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { QueryListItem } from './QueryListItem';

const sortOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Last edit', value: 'lastEdit.time' },
];

export function OpenDialog({ open, onClose, collection, username }) {
  const [
    {
      deleteOpen,
      filterText,
      order,
      orderBy,
      anchorEl,
      userId,
      selectedIdentifier,
    },
    setState,
  ] = useState({
    deleteOpen: false,
    filterText: '',
    order: 'asc',
    orderBy: 'title',
    anchorEl: null,
    userId: username,
    selectedIdentifier: null,
  });
  const { data: userQueries } = useUserQueries({ userId, collection });
  const { data: userIds } = useUserQueryUserIds();
  const { mutate: deleteUserQuery } = useDeleteUserQuery();

  const handleListItemClick = (identifier) => () => {
    setState((state) => ({
      ...state,
      selectedIdentifier: identifier,
    }));
  };

  function handleFilterTextChange(event) {
    setState((state) => ({
      ...state,
      filterText: event.target.value,
    }));
  }

  function handleSortClick(event) {
    setState((state) => ({
      ...state,
      anchorEl: event.currentTarget,
    }));
  }

  function handleSortClose() {
    setState((state) => ({
      ...state,
      anchorEl: null,
    }));
  }

  const handleDeleteClick = (identifier) => () => {
    setState((state) => ({
      ...state,
      selectedIdentifier: identifier,
      deleteOpen: true,
    }));
  };

  function handleDelete() {
    deleteUserQuery(selectedIdentifier);
    setState((state) => ({
      ...state,
      selectedIdentifier: null,
      deleteOpen: false,
    }));
  }

  function handleDeleteCancel() {
    setState((state) => ({
      ...state,
      deleteOpen: false,
    }));
  }

  const handleSortMenuItemClick = (orderBy) => () => {
    setState((state) => ({
      ...state,
      orderBy,
      order:
        state.orderBy === orderBy
          ? state.order === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc',
    }));
  };

  function handleOpenClick() {
    const query = userQueries.find((q) => q.identifier === selectedIdentifier);
    onClose(query);
  }

  const handleListItemDoubleClick = (identifier) => () => {
    const query = userQueries.find((q) => q.identifier === identifier);
    onClose(query);
  };

  function handleUserChange(event, user) {
    setState((state) => ({
      ...state,
      user,
    }));
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Open query</DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        {userQueries.length > 0 ? (
          <Fragment>
            <Stack spacing={1}>
              {userIds.length > 1 && (
                <Autocomplete
                  size="small"
                  fullWidth
                  value={userId}
                  options={userIds}
                  onChange={handleUserChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Select user"
                    />
                  )}
                />
              )}
              <SearchBox
                fullWidth
                value={filterText}
                onChange={handleFilterTextChange}
              />
              <List disablePadding dense>
                {stableSort(
                  userQueries.filter((query) =>
                    query.title?.toLowerCase().includes(filterText),
                  ),
                  getComparator(order, orderBy),
                ).map((query, index) => {
                  return (
                    <Fragment key={index}>
                      {index !== 0 && <Divider />}
                      <QueryListItem
                        title={query.title}
                        lastEditTime={query.lastEdit.time}
                        interval={query.interval}
                        onItemClick={handleListItemClick(query.identifier)}
                        onItemDoubleClick={handleListItemDoubleClick(
                          query.identifier,
                        )}
                        onDeleteClick={handleDeleteClick(query.identifier)}
                        selected={query.identifier === selectedIdentifier}
                      />
                    </Fragment>
                  );
                })}
              </List>
            </Stack>
          </Fragment>
        ) : (
          <Typography color="textSecondary" sx={{ px: 2 }}>
            You have not saved any queries for this report yet
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title="Sort">
          <IconButton aria-label="Sort" size="small" onClick={handleSortClick}>
            <SortIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleSortClose}
        >
          <MenuList dense disablePadding>
            {sortOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={handleSortMenuItemClick(option.value)}
              >
                <ListItemText>{option.label}</ListItemText>
                {orderBy === option.value && (
                  <ArrowUpwardIcon
                    color="action"
                    fontSize="small"
                    sx={(theme) => ({
                      ml: 1,
                      transform:
                        order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    })}
                  />
                )}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Box style={{ flex: 1 }} />
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOpenClick}
          color="primary"
          disabled={!selectedIdentifier}
        >
          Open
        </Button>
      </DialogActions>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        onOk={handleDelete}
        onCancel={handleDeleteCancel}
      />
    </Dialog>
  );
}
