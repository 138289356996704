import {
  CaptionedImage,
  DetailsPanel,
  RetrospectiveTypeIcon,
} from '@/components/controls';
import { useDocumentTitle, useNicheOccurrence, useOptionLookup } from '@/hooks';
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

export function TransportOffence({
  id,
  background,
  color,
  reportedTime,
  startTime,
  endTime,
  statusCode,
  summary,
  typeId,
}) {
  useDocumentTitle(
    ['IR3', 'Transport Offences', id].filter(Boolean).join(' | '),
  );
  const { data: occurrence } = useNicheOccurrence(id, {
    addresses: true,
    officers: true,
    people: true,
    vehicles: true,
  });
  const transportEventTypes = useOptionLookup('transportOffenceType');
  const occurrenceStatuses = useOptionLookup('occurrenceStatus');
  const personClassifications = useOptionLookup('personClassification');
  const officerClassifications = useOptionLookup('officerClassification');

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={
          <Badge>
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="transportOffences" />
            </Avatar>
          </Badge>
        }
        title={id}
        subheader={transportEventTypes[typeId]}
        action={<Chip label={occurrenceStatuses[statusCode]} />}
      />
      <Stack spacing={2} sx={{ p: 2, pt: 0 }}>
        <Typography variant="body2">{summary}</Typography>
        <DetailsPanel
          entries={[
            {
              label: 'Reported',
              value: reportedTime
                ? format(reportedTime, 'dd/MM/yyyy H:mm')
                : '',
            },
            {
              label: 'Occurred',
              value: [
                startTime && format(startTime, 'dd/MM/yyyy H:mm'),
                endTime && format(endTime, 'dd/MM/yyyy H:mm'),
              ]
                .filter(Boolean)
                .join(' - '),
            },
          ]}
        />
        {occurrence?.people?.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ overflowX: 'auto' }}>
            {occurrence.people.map((person) => (
              <CaptionedImage
                key={person.id}
                imageSrc={person.imageSrc}
                height={320}
                fallbackWidth={240}
                topCaption={(person.classificationCodes ?? [])
                  .map((code) => personClassifications[code] ?? code)
                  .join(', ')}
                bottomCaption={[person.forenames, person.surname]
                  .join(' ')
                  .toLowerCase()}
                bottomCaptionProps={{ sx: { textTransform: 'capitalize' } }}
              />
            ))}
          </Stack>
        )}
        {occurrence?.officers?.length > 0 && (
          <DetailsPanel
            title="Officers"
            entries={occurrence.officers.map(
              ({ collarNumber, classificationCodes }) => ({
                label: collarNumber,
                value: (
                  <Typography variant="caption">
                    {classificationCodes
                      .map((code) => officerClassifications[code] ?? code)
                      .join(', ')}
                  </Typography>
                ),
              }),
            )}
          />
        )}
        {occurrence?.vehicles?.length > 0 && (
          <DetailsPanel
            title="Vehicles"
            entries={occurrence.vehicles.map(
              ({ registration, make, model, description }) => ({
                label: registration || 'Unknown Registration',
                value: [make, model, description].filter(Boolean).join(' '),
              }),
            )}
          />
        )}
        {occurrence?.addresses?.length > 0 && (
          <DetailsPanel
            title="Addresses"
            entries={occurrence.addresses.map(
              ({ classificationCodes, label }) => ({
                label,
                value: (
                  <Typography variant="caption">
                    {classificationCodes.join(', ')}
                  </Typography>
                ),
              }),
            )}
          />
        )}
      </Stack>
    </Card>
  );
}
