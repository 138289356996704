import { useScenes } from '@/hooks';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Avatar, Box, IconButton, Stack, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useCallback, useRef, useState } from 'react';
import { SceneEditor } from './SceneEditor';
import { SceneMap } from './SceneMap';
import { bbox } from '@turf/turf';

const sceneColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Incident',
    accessorKey: 'attributes.incidentCode',
  },
  {
    header: 'Occurrence',
    accessorKey: 'attributes.occurrenceNumber',
  },
  {
    header: 'Start Time',
    key: 'startTime',
    accessorFn: (row) =>
      row.startTime?.toLocaleString('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    header: 'End Time',
    key: 'endTime',
    accessorFn: (row) =>
      row.endTime?.toLocaleString('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    header: 'Groups',
    accessorFn: (row) => (row.groups ?? []).sort().join(', '),
    key: 'groups',
  },
  {
    header: 'Required Attendance',
    accessorKey: 'attributes.requiredAttendance',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Current Attendance',
    accessorKey: 'currentAttendanceCount',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

export function Scenes({ groupCodes }) {
  const { data: scenes, isLoading, isFetching } = useScenes(groupCodes);
  const [editCode, setEditCode] = useState(null);
  const mapRef = useRef();

  function handleAddClick() {
    setEditCode('new');
  }

  function onEditorClose() {
    setEditCode(null);
  }

  const handleRowClick = (boundary) => () => {
    if (boundary) {
      mapRef.current.fitBounds(bbox(boundary), {
        padding: 40,
        duration: 1000,
      });
    }
  };

  const handleEditClick = (code) => (event) => {
    event.stopPropagation();
    setEditCode(code);
  };

  const handleMapClick = useCallback(
    (event) => {
      setEditCode(scenes[event.features?.[0]?.id]?.code);
    },
    [scenes],
  );

  return (
    <Stack spacing={1}>
      <SceneMap scenes={scenes} onClick={handleMapClick} mapRef={mapRef} />
      <MaterialReactTable
        muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
        defaultColumn={{ size: 0, enableEditing: false }}
        columns={sceneColumns}
        data={scenes}
        enableBottomToolbar={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePagination={false}
        state={{ isLoading: isLoading || isFetching }}
        initialState={{
          density: 'compact',
          expanded: true,
          sorting: [{ id: 'name', desc: false }],
          columnVisibility: { remarks: false },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => {
          return (
            <Tooltip title="Edit" placement="left">
              <IconButton
                size="small"
                onClick={handleEditClick(row.original?.code)}
              >
                <EditIcon sx={{ fontSize: 'inherit' }} />
              </IconButton>
            </Tooltip>
          );
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Stack
              width={1}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box>
                <Tooltip title="Add Scene" placement="left">
                  <IconButton size="small" onClick={handleAddClick}>
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        bgcolor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                        m: 0.25,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          );
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: handleRowClick(row.original?.boundary),
          sx: {
            cursor: 'pointer',
            '&:last-child td, &:last-child th': { border: 0 },
          },
        })}
        muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
      />
      <SceneEditor
        code={editCode}
        isOpen={Boolean(editCode)}
        onClose={onEditorClose}
        groupCodes={groupCodes}
      />
    </Stack>
  );
}
