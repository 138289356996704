import { useDocumentTitle } from '@/hooks';
import { Typography } from '@mui/material';

export function TextHeader({ title }) {
  useDocumentTitle(`IR3 | ${title}`);

  return (
    <Typography variant="h6" sx={{ flexGrow: 1 }}>
      {title}
    </Typography>
  );
}
