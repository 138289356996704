import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertIntelligenceItem() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      return json.code
        ? briefingApi.patch(`intelligence/${json.code}`, { json }).json()
        : briefingApi.post('intelligence', { json }).json();
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['intelligence'] }),
  });
}
