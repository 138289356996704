import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useTeamMembers(groupCodes = []) {
  return useQuery({
    queryKey: ['people', groupCodes],
    queryFn: () => {
      const json = [
        {
          $match: {
            deleted: { $ne: true },
            $or: [
              { leavingDate: { $exists: false } },
              { leavingDate: { $gte: new Date() } },
            ],
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
            depthField: 'depth',
          },
        },
        {
          $project: {
            code: true,
            hasPicture: { $gt: ['$picture', null] },
            forenames: true,
            surname: true,
            collarNumber: true,
            rank: true,
            role: true,
            radioSsi: true,
            rfidCards: true,
            lastPollTime: true,
            assignments: true,
            emailAddress: true,
            mobileNumber: true,
            groupNames: {
              $map: {
                input: {
                  $filter: {
                    input: '$groupAncestorCodes',
                    as: 'group',
                    cond: { $eq: ['$$group.depth', 0] },
                  },
                },
                as: 'group',
                in: '$$group.name',
              },
            },
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
          },
        },
        groupCodes.length > 0 && {
          $match: { groupAncestorCodes: { $in: groupCodes } },
        },
      ].filter(Boolean);

      log('Read', 'Team Members', { groupCodes });

      return api.post('pipeline/people', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
