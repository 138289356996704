import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useAddOptionValue() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, entry }) => {
      const json = [
        { $match: { name, value } },
        { $project: { values: { $concatArrays: ['$values', [entry]] } } },
        {
          $merge: {
            into: 'options',
            whenMatched: 'merge',
            whenNotMatched: 'discard',
          },
        },
      ];

      return api.post('pipeline/options', { json }).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}
