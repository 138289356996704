import { api } from '@/apis';
import { NormalDistribution, epochHoursToHistogram, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getUnixTime } from 'date-fns';
import { mean as meanValue, std as standardDeviation } from 'mathjs';

export function usePersonAvailability(
  startTime,
  endTime,
  filter,
  customConfidence,
) {
  return useQuery({
    queryKey: [
      'personAvailability',
      startTime,
      endTime,
      filter,
      customConfidence,
    ],
    queryFn: async ({ signal }) => {
      const isoStart = startTime.toISOString();
      const isoEnd = endTime.toISOString();
      const grouping = 'role';

      const json = [
        {
          $match: {
            startTime: {
              $lt: isoEnd,
            },
            endTime: {
              $gte: isoStart,
            },
            'value.category': 'Available', //{ $exists: true }
            $expr: {
              // more than half an hour
              $gt: [{ $subtract: ['$endTime', '$startTime'] }, 30 * 60 * 1000],
            },
          },
        },
        {
          $project: {
            code: true,
            startTime: true,
            endTime: true,
            // category: "$value.category",
          },
        },
        {
          $group: {
            //null,
            _id: {
              code: '$code',
              // category: '$category'
            },
            hours: {
              $push: {
                $map: {
                  input: {
                    $range: [
                      {
                        $floor: {
                          $divide: [
                            {
                              $subtract: [
                                '$startTime',
                                { $toDate: '1970-01-01T00:00:00.000Z' },
                              ],
                            },
                            1000,
                          ],
                        },
                      },
                      {
                        $floor: {
                          $divide: [
                            {
                              $subtract: [
                                '$endTime',
                                { $toDate: '1970-01-01T00:00:00.000Z' },
                              ],
                            },
                            1000,
                          ],
                        },
                      },
                      3600,
                    ],
                  },
                  as: 'time',
                  in: {
                    $floor: {
                      $divide: [
                        {
                          $toDecimal: '$$time',
                        },
                        3600,
                      ],
                    },
                  },
                },
              },
            },
          },
        },
        // { $sort: { _id: 1 } },
      ];

      const [locationsResult, peopleResult, attributionChangesResult] =
        await Promise.all([
          api
            .post('pipeline/locations', {
              json: [{ $project: { code: true, name: true, type: true } }],
            })
            .json(),
          api
            .post('pipeline/people', {
              json: [
                {
                  $project: { code: true, homeStation: true, [grouping]: true },
                },
              ],
            })
            .json(),
          api.post('pipeline/personAttributions', { json, signal }).json(),
        ]);

      const locationsByCode = Object.fromEntries(
        locationsResult.map((location) => [location.code, location]),
      );
      const peopleByCode = Object.fromEntries(
        peopleResult.map((person) => [person.code, person]),
      );

      // an epochHour is the number of hours since 1/1/1970
      function isoDateToEpochHour(isoDate) {
        if (isoDate) {
          return Math.floor(getUnixTime(new Date(isoDate)) / 3600);
        }

        return null;
      }
      const startEpochHour = isoDateToEpochHour(isoStart);
      const endEpochHour = isoDateToEpochHour(isoEnd);

      function getKey(stop) {
        return stop.homeStation + stop.grouping;
      }

      const attributionHoursByKey = {};
      attributionChangesResult.forEach((a) => {
        const person = peopleByCode[a._id?.code];

        if (person) {
          const key = getKey({
            homeStation: person.homeStation,
            grouping: person[grouping],
          });

          if (!attributionHoursByKey[key]) {
            attributionHoursByKey[key] = {
              key,
              homeStation: person.homeStation,
              grouping: person[grouping],
              hours: [],
            };
          }

          Array.prototype.push.apply(attributionHoursByKey[key].hours, a.hours);
        }
      });

      let statsPerHomeStationAndGrouping = {};
      Object.values(attributionHoursByKey).forEach(
        ({ key, homeStation, grouping, hours }) => {
          const location = locationsByCode[homeStation];

          const [availabilities, histogram] = epochHoursToHistogram(
            startEpochHour,
            endEpochHour,
            hours,
          );
          const instanceArray = Object.values(histogram).map((h) =>
            new Array(h.hours).fill(h.count),
          );
          const std = standardDeviation(instanceArray);
          const mean = meanValue(instanceArray);

          const normalDistribution = new NormalDistribution(mean, std);
          function invp(p) {
            const result = normalDistribution.invCumulativeProbability(1 - p);
            return result > 0 ? result : 0;
          }

          statsPerHomeStationAndGrouping[key] = {
            stopKey: key,
            homeStation: location?.name || homeStation,
            // locationType: location?.type || 'Unknown',
            grouping,
            // p95: inv95 * std,
            // p975: 2.5 * std, //inv975 * std,
            // p99: inv99 * std,
            pCustom: invp(customConfidence / 100),
            p95: invp(0.95),
            p975: invp(0.975),
            p99: invp(0.99),
            std,
            mean,
            availabilities,
            histogram,
          };
        },
      );

      const data = Object.values(statsPerHomeStationAndGrouping);

      // TODOJL!
      const filterOptions = {
        homeStation: Array.from(new Set(data.map((l) => l.homeStation))).sort(),
        // locationType: Array.from(new Set(data.map((l) => l.locationType))).sort(),
        grouping: Array.from(new Set(data.map((l) => l.grouping))).sort(),
      };

      const filteredData = data.filter((record) =>
        Object.keys(filter).every(
          (key) =>
            (filter[key]?.length || 0) === 0 ||
            filter[key].includes(record[key]),
        ),
      );

      log('Read', 'Person Availability', { startTime, endTime });

      return {
        filterOptions,
        filteredData,
        data,
      };
    },
    placeholderData: {
      filterOptions: {},
      filteredData: [],
      data: [],
    },
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}
