import { InspectorsNoticeBoard } from './InspectorsNoticeBoard';
import { MissingPersons } from './MissingPersons';
import { Placeholder } from './Placeholder';
import { PriorityIntelligence } from './PriorityIntelligence';
import { Subjects } from './Subjects';
import { Teams } from './Teams';
import { UrgentActions } from './UrgentActions';
import { Vehicles } from './Vehicles';
import { Watches } from './Watches';

export function Section({ id, ...props }) {
  switch (id) {
    case 'teams':
      return <Teams {...props} />;
    case 'vehicles':
      return <Vehicles {...props} />;
    case 'inspectorsNoticeBoard':
      return <InspectorsNoticeBoard {...props} />;
    case 'urgentActions':
      return <UrgentActions {...props} />;
    case 'missingPersons':
      return <MissingPersons {...props} />;
    case 'priorityIntelligence':
      return <PriorityIntelligence {...props} />;
    case 'watches':
      return <Watches {...props} />;
    case 'subjects':
      return <Subjects {...props} />;
    case 'prisoners':
    case 'antisocialBehaviour':
    case 'sexualOffences':
    case 'burglaries':
    case 'vulnerable':
    case 'arrests':
    case 'suddenDeaths':
    case 'mentalHealthIncidents':
    case 'violentCrimes':
    case 'prisonRecalls':
    case 'outstandingWarrants':
    case 'bailChecks':
    case 'returns':
    case 'community':
    case 'outstandingSuspects':
    case 'firearmsIncidents':
    case 'pursuits':
    case 'seriousCollisions':
    case 'domesticAbuseStalkingHarassment':
    case 'crimesAgainstStaff':
    case 'repeatVictims':
    case 'hateCrimes':
    case 'useOfForceOutstanding':
    case 'oldOccurrences':
    case 'liveCaseFiles':
    default:
      return <Placeholder id={id} {...props} />;
  }
}
