import { alpha, Box, Typography } from '@mui/material';

export function CaptionedImage({
  imageSrc,
  topCaption,
  bottomCaption,
  height,
  width,
  fallbackWidth,
  fallbackHeight,
  lockDimension = 'height',
  alt,
  topCaptionProps,
  bottomCaptionProps,
}) {
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        borderRadius: 1,
        bgcolor: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.300',
        overflow: 'hidden',
        height:
          lockDimension === 'width'
            ? imageSrc
              ? undefined
              : fallbackHeight
            : height,
        width:
          lockDimension === 'height'
            ? imageSrc
              ? undefined
              : fallbackWidth
            : width,
        flexShrink: 0,
      })}
    >
      {imageSrc && <img src={imageSrc} alt={alt} height={height} />}
      {topCaption && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 0,
            width: 1,
            color: 'white',
            bgcolor: alpha(theme.palette.common.black, 0.5),
            padding: 1,
          })}
        >
          <Typography {...topCaptionProps}>{topCaption}</Typography>
        </Box>
      )}
      {bottomCaption && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            bottom: 0,
            width: 1,
            color: 'white',
            bgcolor: alpha(theme.palette.common.black, 0.5),
            padding: 1,
          })}
        >
          <Typography {...bottomCaptionProps}>{bottomCaption}</Typography>
        </Box>
      )}
    </Box>
  );
}
