import { Female as FemaleIcon, Male as MaleIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { blue, pink } from '@mui/material/colors';

export function GenderIcon({ gender }) {
  switch (gender?.toUpperCase()) {
    case 'MALE':
      return (
        <Tooltip title="Male">
          <MaleIcon sx={{ color: blue[200], fontSize: 16 }} />
        </Tooltip>
      );
    case 'FEMALE':
      return (
        <Tooltip title="Female">
          <FemaleIcon sx={{ color: pink[200], fontSize: 16 }} />
        </Tooltip>
      );
    default:
      return null;
  }
}
