import { getTextWidth } from '@/utils';
import {
  DoNotDisturb as DoNotDisturbIcon,
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  RemoveDone as RemoveDoneIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  List,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Fragment, forwardRef, useRef } from 'react';
import { Adornment } from '..';

const conditions = [
  {
    label: <DoneIcon fontSize="inherit" />,
    value: 'includes',
    title: 'Includes',
  },
  {
    label: <DoNotDisturbIcon fontSize="inherit" />,
    value: 'excludes',
    title: 'Excludes',
  },
  {
    label: <DoneAllIcon fontSize="inherit" />,
    value: 'any',
    title: 'Any',
  },
  {
    label: <RemoveDoneIcon fontSize="inherit" />,
    value: 'none',
    title: 'None',
  },
];

// function DynamicWidthPopper({ style: { width, ...style }, ...props }) {
//   return <Popper {...props} style={style} placement="bottom-start" />;
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const getComputedWidthPopper = (options, font, padding) =>
  function ComputedWidthPopper({ style: { width, ...style }, ...props }) {
    const computedWidth =
      Math.ceil(
        Math.max(
          ...(options || []).map((option) =>
            getTextWidth(
              option.label ?? options.find((o) => o.value === option).label,
              font,
            ),
          ),
        ),
      ) + padding;

    return (
      <StyledPopper
        {...props}
        style={{ width: Math.max(computedWidth, width), ...style }}
        placement="bottom-start"
      />
    );
  };

const VirtualisedListbox = forwardRef(function VirtualisedListbox(
  { children: options, role, ...props },
  ref,
) {
  const scrollRef = useRef(null);
  const virtualizer = useVirtualizer({
    count: options.length,
    estimateSize: () => 36, //* NOTE: You may have to play around with this value.
    getScrollElement: () => scrollRef.current,
  });

  return (
    <Box ref={ref}>
      <List
        {...props}
        role={role}
        ref={scrollRef}
        component="div"
        sx={{
          position: 'relative',
          height: virtualizer.getTotalSize(),
        }}
      >
        {virtualizer.getVirtualItems().map((item) => {
          const [{ key, ...props }, option] = options[item.index];

          return (
            <Typography
              component="li"
              key={key}
              {...props}
              noWrap
              sx={{
                py: 0.5,
                width: '100%',
                position: 'absolute',
                transform: `translateY(${item.start}px)`,
              }}
            >
              {option.label}
            </Typography>
          );
        })}
      </List>
    </Box>
  );
});

export function SelectFilterField({
  value,
  onChange,
  multiple,
  options,
  size,
  ...props
}) {
  const empty = multiple ? [] : null;
  const { condition, selection } = value ?? {
    condition: 'includes',
    selection: empty,
  };
  const disabled = ['any', 'none'].includes(condition);

  function handleSelectionChange(event, selection) {
    onChange({
      condition,
      selection: disabled
        ? empty
        : multiple
          ? selection.map((option) => option.value ?? option)
          : selection?.value,
    });
  }

  function handleConditionChange(condition) {
    const disabled = ['any', 'none'].includes(condition);
    onChange({ condition, selection: disabled ? empty : selection });
  }

  return (
    <Autocomplete
      value={selection ?? empty}
      onChange={handleSelectionChange}
      multiple={multiple}
      size={size}
      options={options}
      disabled={disabled}
      getOptionLabel={(option) =>
        option.label ?? options.find((o) => o.value === option).label
      }
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            disabled
              ? conditions.find((c) => c.value === condition)?.title
              : undefined
          }
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: (
                <Fragment>
                  <Adornment
                    value={condition}
                    values={conditions}
                    onChange={handleConditionChange}
                    position="start"
                    sx={{ pl: 0.5, pt: 0.75 }}
                  />
                  {params.InputProps.startAdornment}
                </Fragment>
              ),
            },
          }}
          {...props}
        />
      )}
      PopperComponent={getComputedWidthPopper(options, '16px Roboto', 44)}
      disableListWrap
      ListboxComponent={VirtualisedListbox}
      renderOption={(props, option) => [props, option]}
    />
  );
}
