import { LoadingDialog } from '@/components/controls';
import { useDeleteNotice, useNotice, useUpsertNotice } from '@/hooks';
import { NoticeEditorDialog } from './NoticeEditorDialog';

export function NoticeEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: notice } = useNotice(code, {
    groupCodes: groupCodes ?? [],
    message: '',
    imageSrc: '',
    linkHref: '',
    nominalId: '',
  });
  const { mutate: upsertNotice } = useUpsertNotice();
  const { mutate: deleteNotice } = useDeleteNotice();

  function handleClose(values) {
    if (values) {
      upsertNotice(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteNotice(code);

    onClose();
  }

  return notice ? (
    <NoticeEditorDialog
      notice={notice}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}
