import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertNotice() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      return json.code
        ? briefingApi.patch(`notices/${json.code}`, { json }).json()
        : briefingApi.post('notices', { json }).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['notices'] }),
  });
}
