import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { cloneElement } from 'react';
import { VirtualisedListbox } from '../controls';

const LISTBOX_PADDING = 8; // px

const labelValueFilter = createFilterOptions({
  stringify: (option) => option.value,
});

function renderRow(props) {
  const { data, index, style } = props;
  const { styles = {} } = data;
  const { children: option } = data[index].props;

  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      ...(styles[option?.className] || {}),
      whiteSpace: 'nowrap',
    },
  });
}

export function AutosuggestField({
  input: { value, onChange },
  meta,
  suggestions,
  disabled,
  styles = {},
  onSelect,
  freeSolo,
  selectOnFocus,
  clearOnBlur,
  handleHomeEndKeys,
  className,
  ...props
}) {
  function handleChange(event, suggestion) {
    suggestion = suggestion?.value ?? suggestion ?? '';
    onChange(suggestion);
    onSelect?.(suggestion);
  }

  const newValue = freeSolo && value ? { label: value, value } : null;

  function addSuggestion(options, inputState) {
    const { inputValue } = inputState;

    const add =
      freeSolo && !!inputValue && !options.find((o) => o.value === inputValue)
        ? { label: `Add "${inputValue}"`, value: inputValue }
        : null;

    return labelValueFilter(add ? [...options, add] : options, inputState);
  }

  const filterOptions = freeSolo ? addSuggestion : undefined;

  return (
    <Autocomplete
      size="small"
      fullWidth
      ListboxComponent={VirtualisedListbox}
      ListboxProps={{ styles, options: suggestions, renderRow }}
      disableListWrap
      value={
        suggestions.find((suggestion) => suggestion.value === value) ?? newValue
      }
      disabled={disabled}
      onChange={handleChange}
      options={suggestions}
      getOptionLabel={(option) => option.label ?? option ?? ''}
      filterOptions={filterOptions}
      renderInput={(params) => {
        const option = suggestions.find((s) => s.value === value);

        return (
          <TextField
            size="small"
            {...params}
            {...props}
            helperText={meta.error ?? meta.submitError}
            error={meta.invalid}
            style={styles[option?.className]}
          />
        );
      }}
      freeSolo={freeSolo}
      selectOnFocus={selectOnFocus}
      clearOnBlur={clearOnBlur}
      handleHomeEndKeys={handleHomeEndKeys}
      className={className}
    />
  );
}
