import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useTeamVehicles(groupCodes = []) {
  return useQuery({
    queryKey: ['vehicles', groupCodes],
    queryFn: async () => {
      const json = [
        groupCodes.length > 0 && {
          $match: { groupCodes: { $in: groupCodes } },
        },
        {
          $lookup: {
            from: 'vehicleStates',
            localField: 'identificationNumber',
            foreignField: 'identificationNumber',
            pipeline: [
              { $match: { type: 'Check' } },
              { $sort: { time: -1 } },
              { $limit: 1 },
              { $project: { _id: false, time: true } },
            ],
            as: 'lastCheck',
          },
        },
        {
          $project: {
            _id: false,
            identificationNumber: '$identificationNumber',
            fleetNumber: '$fleetNumber',
            registrationNumber: '$registrationNumber',
            role: '$role',
            type: '$type',
            make: '$make',
            model: '$model',
            keyNumber: '$keyNumber',
            colour: '$colour',
            isMarked: '$marked',
            imei: '$telematicsBoxImei',
            driver: {
              $switch: {
                branches: [
                  {
                    case: {
                      $not: {
                        $setIsSubset: [
                          ['$driver.identificationReference'],
                          [null, ''],
                        ],
                      },
                    },
                    then: {
                      code: '$driver.code',
                      forenames: '$driver.forenames',
                      surname: '$driver.surname',
                      collarNumber: '$driver.collarNumber',
                      identificationReference:
                        '$driver.identificationReference',
                      isCurrent: true,
                    },
                  },
                  {
                    case: {
                      $not: {
                        $setIsSubset: [
                          ['$lastDriver.identificationReference'],
                          [null, ''],
                        ],
                      },
                    },
                    then: {
                      code: '$lastDriver.code',
                      forenames: '$lastDriver.forenames',
                      surname: '$lastDriver.surname',
                      collarNumber: '$lastDriver.collarNumber',
                      identificationReference:
                        '$lastDriver.identificationReference',
                      isCurrent: false,
                    },
                  },
                ],
                default: null,
              },
            },
            lastCheckTime: { $first: '$lastCheck.time' },
          },
        },
      ].filter(Boolean);

      log('Read', 'Team Vehicles', { groupCodes });

      return api.post('pipeline/vehicles', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
