import { LoadingDialog } from '@/components/controls';
import {
  useDeleteIntelligenceItem,
  useIntelligenceItem,
  useUpsertIntelligenceItem,
} from '@/hooks';
import { IntelligenceItemEditorDialog } from './IntelligenceItemEditorDialog';

export function IntelligenceItemEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: item } = useIntelligenceItem(code, {
    groupCodes: groupCodes ?? [],
    message: '',
    imageSrc: '',
    intelligenceId: '',
    personCode: '',
  });
  const { mutate: upsertItem } = useUpsertIntelligenceItem();
  const { mutate: deleteItem } = useDeleteIntelligenceItem();

  function handleClose(values) {
    if (values) {
      upsertItem(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteItem(code);

    onClose();
  }

  return item ? (
    <IntelligenceItemEditorDialog
      item={item}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}
