import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { addHours } from 'date-fns';

interface Source {
  collarNumber: string;
  name: string;
  teamId: number;
}

interface Shift {
  _id: string;
  team: string;
  source: Source;
  shiftName: string;
  callSign: string;
  phone?: string;
  remarks: string;
  startTime: Date;
  endTime: Date;
  activeSeconds: number;
  type: string;
  groupCode?: string;
  groupSubtype?: string;
  ancestorCodes: string[];
  period: string;
}

export function useShifts(
  date: Date,
  groups: string[] = [],
): UseQueryResult<Shift[]> {
  return useQuery({
    queryKey: ['shifts', date, groups],
    queryFn: () => {
      const json = [
        {
          $match: {
            startTime: { $gte: addHours(date, 4), $lt: addHours(date, 28) },
          },
        },
        {
          $lookup: {
            from: 'groups',
            localField: 'team',
            foreignField: 'aliases.GLOBAL_ROSTERING_SYSTEM',
            as: 'groups',
          },
        },
        {
          $set: {
            groupCode: { $first: '$groups.code' },
            groupSubtype: { $first: '$groups.subtype' },
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCode',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'ancestorCodes',
          },
        },
        {
          $lookup: {
            from: 'options',
            localField: 'shiftName',
            foreignField: 'values',
            pipeline: [{ $match: { name: 'shiftType' } }],
            as: 'types',
          },
        },
        {
          $project: {
            startTime: true,
            endTime: true,
            activeSeconds: true,
            team: true,
            source: true,
            callSign: true,
            remarks: true,
            shiftName: true,
            type: { $ifNull: [{ $first: '$types.value' }, ''] },
            groupCode: true,
            groupSubtype: true,
            ancestorCodes: {
              $map: {
                input: '$ancestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            period: {
              $switch: {
                branches: [
                  {
                    case: { $eq: ['$activeSeconds', 0] },
                    then: 'Off',
                  },
                  {
                    case: {
                      $and: [
                        {
                          $gte: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            4,
                          ],
                        },
                        {
                          $lt: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            11,
                          ],
                        },
                      ],
                    },
                    then: 'Day',
                  },
                  {
                    case: {
                      $and: [
                        {
                          $gte: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            11,
                          ],
                        },
                        {
                          $lt: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            19,
                          ],
                        },
                      ],
                    },
                    then: 'Late',
                  },
                  {
                    case: {
                      $or: [
                        {
                          $gte: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            19,
                          ],
                        },
                        {
                          $lt: [
                            {
                              $hour: {
                                date: '$startTime',
                                timezone: 'Europe/London',
                              },
                            },
                            4,
                          ],
                        },
                      ],
                    },
                    then: 'Night',
                  },
                ],
                default: '-',
              },
            },
          },
        },
        groups.length > 0 && { $match: { ancestorCodes: { $in: groups } } },
        { $sort: { startTime: 1, shiftName: 1 } },
      ].filter(Boolean);

      log('Read', 'Shifts', { date, groups });

      return api.post('pipeline/personShifts', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
