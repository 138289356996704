import { LoadingDialog } from '@/components/controls';
import { useDeleteWatch, useWatch, useUpsertWatch } from '@/hooks';
import { PrisonerEditorDialog } from './PrisonerEditorDialog';

export function PrisonerEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: prisoner } = useWatch(code, {
    name: '',
    custodyNumber: '',
    occurrenceNumber: '',
    offence: '',
    arrestingOfficerCode: '',
    investigatingOfficerCode: '',
    officerInCaseCode: '',
    arrivalTime: '',
    custodyUnit: '',
    observationLevel: '',
  });
  const { mutate: upsertPrisoner } = useUpsertWatch();
  const { mutate: deletePrisoner } = useDeleteWatch();

  function handleClose(values) {
    if (values) {
      upsertPrisoner(values);
    }

    onClose();
  }

  function handleDelete() {
    deletePrisoner(code);

    onClose();
  }

  return prisoner ? (
    <PrisonerEditorDialog
      prisoner={prisoner}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
      groupCodes={groupCodes}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}
