import { usePerson } from '@/hooks';
import { KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';

export function OfficerInCase({ code }) {
  const { data: person } = usePerson(code);

  return (
    person && (
      <Tooltip title="Officer in Case" placement="left-start">
        <Stack direction="row">
          <KeyboardDoubleArrowUpIcon fontSize="small" />
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {person.collarNumber}
          </Typography>
        </Stack>
      </Tooltip>
    )
  );
}
