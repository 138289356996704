import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSharedMissingPersons(groupCodes) {
  return useQuery({
    queryKey: ['sharedMissingPersons', groupCodes],
    queryFn: () => {
      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );

      return briefingApi.get('shared-missing-persons', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
