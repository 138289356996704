import { Done as DoneIcon } from '@mui/icons-material';
import {
  alpha,
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { PrisonerWatches } from './PrisonerWatches';
import { Scenes } from './Scenes';

export function Watches({ groupCodes, onComplete }) {
  const [tab, setTab] = useState('prisoners');

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Box sx={{ px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={(theme) => ({
          position: 'sticky',
          top: 48,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          zIndex: 700,
          pl: 1,
          py: 1,
        })}
      >
        <Typography variant="h6">Watches</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Tabs textColor="inherit" value={tab} onChange={handleTabChange}>
          <Tab label="Prisoner & Hospital" value="prisoners" />
          <Tab label="Scenes" value="scenes" />
        </Tabs>
      </Stack>
      {tab === 'scenes' ? (
        <Scenes groupCodes={groupCodes} />
      ) : (
        <PrisonerWatches groupCodes={groupCodes} />
      )}
    </Box>
  );
}
