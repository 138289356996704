import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useWatch(code) {
  return useQuery({
    queryKey: ['watches', code],
    queryFn: () => {
      if (code === 'new') {
        return {
          name: '',
          custodyNumber: '',
          occurrenceNumber: '',
          arrestingOfficerCode: '',
          investigatingOfficerCode: '',
          officerInChargeCode: '',
          arrivalTime: null,
          custodyUnit: '',
          observationLevel: '',
        };
      }

      return briefingApi.get(`watches/${code}`).json();
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60,
  });
}
