import { DetailsPanel } from '@/components/controls';
import {
  useGroupOptions,
  useNichePerson,
  useOptionLookup,
  useSubject,
} from '@/hooks';
import {
  ElectricBolt as ElectricBoltIcon,
  Facebook as FacebookIcon,
  Folder as FolderIcon,
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
  Map as MapIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
  DesktopClassic as DesktopClassicIcon,
  Pistol as PistolIcon,
} from 'mdi-material-ui';
import { Fragment, useMemo } from 'react';
import { FlagAvatar } from './FlagAvatar';

export function Suspect({ personId, onOptionClick, onShowMapClick }) {
  const subjectType = 'SUSPECT';
  const groupLookup = useGroupOptions();
  const personClassifications = useOptionLookup('personClassification');
  const { data: person } = useNichePerson(personId, {
    addresses: true,
    image: true,
    type: 'suspect',
  });
  const { data: subject } = useSubject(subjectType, personId, {
    type: subjectType,
    personId,
  });
  const groupsEntries = useMemo(
    () =>
      Object.entries(
        (person?.groupCodes ?? [])
          .filter((code) => groupLookup[code])
          .map((code) => groupLookup[code])
          .reduce(
            (acc, group) => ({
              ...acc,
              [group.type]: [...(acc[group.type] ?? []), group.name],
            }),
            {},
          ),
      ).map(([type, names]) => ({
        label: type,
        value: names.join(', '),
      })),
    [person, groupLookup],
  );

  return (
    <Card variant="outlined" sx={{ minWidth: 240 }}>
      <CardHeader
        avatar={<FlagAvatar code={person?.flagCode} />}
        action={
          <Fragment>
            <Tooltip
              title={subject?.highPriority ? 'Deprioritise' : 'Prioritise'}
            >
              <IconButton
                size="small"
                onClick={onOptionClick(
                  subjectType,
                  personId,
                  subject?.code,
                  'highPriority',
                )}
              >
                {subject?.highPriority ? (
                  <KeyboardDoubleArrowDownIcon sx={{ fontSize: 'inherit' }} />
                ) : (
                  <KeyboardDoubleArrowUpIcon sx={{ fontSize: 'inherit' }} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Show map">
              <IconButton size="small" onClick={onShowMapClick}>
                <MapIcon sx={{ fontSize: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </Fragment>
        }
        title={[person?.forenames, person?.surname].join(' ').toLowerCase()}
        sx={{ textTransform: 'capitalize' }}
      />
      {person?.imageSrc ? (
        <CardMedia
          component="img"
          sx={{ minHeight: 240, width: '100%' }}
          image={person.imageSrc}
          alt="Photo of suspect"
        />
      ) : (
        <Box
          sx={(theme) => ({
            height: 240,
            width: '100%',
            bgcolor: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.300',
          })}
        />
      )}
      <Stack spacing={1} sx={{ p: 1 }}>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            variant="body3"
            color="textSecondary"
            sx={{ flexGrow: 1 }}
          >
            {person?.occurrenceNumber}
          </Typography>
          <Typography variant="subtitle2">
            {person?.startTime && format(person?.startTime, 'dd/MM/yyyy HH:mm')}
          </Typography>
        </Stack>
        {person?.classificationCodes?.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ overflow: 'scroll' }}>
            {person.classificationCodes.map((code) => (
              <Chip
                key={code}
                label={personClassifications[code] ?? code}
                size="small"
              />
            ))}
          </Stack>
        )}
        <DetailsPanel
          entries={[
            {
              label: 'Last victim update',
              value: person?.lastVictimUpdatedTime
                ? format(person.lastVictimUpdatedTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
            {
              label: 'Last log entry',
              value: person?.lastLogEntryTime
                ? format(person.lastLogEntryTime, 'dd/MM/yyyy HH:mm')
                : '',
            },
          ]}
        />
        <DetailsPanel
          title="Owned by"
          entries={[
            {
              label: 'OIC',
              value: person?.officerInCase,
            },
            ...groupsEntries,
          ]}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Tooltip
            title={
              subject?.pncCirculated
                ? 'Circulated on PNC'
                : 'Not circulated on PNC'
            }
          >
            <IconButton
              size="small"
              onClick={onOptionClick(
                subjectType,
                personId,
                subject?.code,
                'pncCirculated',
              )}
              color={subject?.pncCirculated ? 'primary' : 'default'}
            >
              <DesktopClassicIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={subject?.fileReady ? 'File ready' : 'File not ready'}>
            <IconButton
              size="small"
              onClick={onOptionClick(
                subjectType,
                personId,
                subject?.code,
                'fileReady',
              )}
              color={subject?.fileReady ? 'primary' : 'default'}
            >
              <FolderIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              subject?.socialMediaCirculated
                ? 'Circulated on social media'
                : 'Not circulated on social media'
            }
          >
            <IconButton
              size="small"
              onClick={onOptionClick(
                subjectType,
                personId,
                subject?.code,
                'socialMediaCirculated',
              )}
              color={subject?.socialMediaCirculated ? 'primary' : 'default'}
            >
              <FacebookIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={subject?.taser ? 'Taser permitted' : 'Taser not permitted'}
          >
            <IconButton
              size="small"
              onClick={onOptionClick(
                subjectType,
                personId,
                subject?.code,
                'taser',
              )}
              color={subject?.taser ? 'primary' : 'default'}
            >
              <ElectricBoltIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              subject?.firearms
                ? 'Firearms permitted'
                : 'Firearms not permitted'
            }
          >
            <IconButton
              size="small"
              onClick={onOptionClick(
                subjectType,
                personId,
                subject?.code,
                'firearms',
              )}
              color={subject?.firearms ? 'primary' : 'default'}
            >
              <PistolIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
}
