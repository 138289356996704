import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNotices(groupCodes) {
  return useQuery({
    queryKey: ['notices', groupCodes],
    queryFn: () => {
      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );

      return briefingApi.get('notices', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
