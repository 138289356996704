import { TextFieldController } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { useOptions } from '@/hooks';
import {
  Person as PersonIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export function RadioForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  onRemove,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [removePerson, setRemovePerson] = useState(null);
  const { data: radioTypes } = useOptions('radioType');
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  const handleRemoveClick = (person) => (event) => {
    event.preventDefault();

    setRemovePerson(person);
  };

  function handleRemove() {
    onRemove(removePerson._id);
    setRemovePerson(null);
  }

  function isDisabled() {
    return !canEdit || !defaultValues?._id;
  }

  return (
    <Fragment>
      <form id="radio-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ p: 1 }}>
            {defaultValues?._id ? defaultValues.ssi : 'New Radio'}
          </Typography>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="ssi"
              label="SSI"
              control={control}
              disabled={isDisabled('ssi')}
              rules={{ required: 'Required', maxLength: 20 }}
            />
            <TextFieldController
              name="type"
              label="Type"
              options={radioTypes}
              control={control}
              disabled={isDisabled('type')}
            />
            <TextFieldController
              name="label"
              label="Label"
              control={control}
              disabled={isDisabled('label')}
              rules={{ maxLength: 50 }}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Assigned People
            </Typography>
          </Divider>
          {defaultValues?.people?.length > 0 ? (
            defaultValues.people.map((person) => (
              <Card
                key={person.code}
                variant="outlined"
                sx={{ width: 320, mr: 1, mb: 1 }}
              >
                <CardActionArea
                  component={Link}
                  to={`/resources/people/${encodeURIComponent(person.code)}`}
                >
                  <CardHeader
                    avatar={
                      <Avatar src={person.picture}>
                        <PersonIcon />
                      </Avatar>
                    }
                    action={
                      <Tooltip title="Remove">
                        <IconButton
                          onClick={handleRemoveClick(person)}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    title={`${person.forenames} ${person.surname}`}
                    subheader={person.collarNumber}
                  />
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              There is no one assigned to this radio
            </Typography>
          )}
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={toggleDeleteDialog}
              disabled={!watch('_id')}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Remove"
        open={!!removePerson}
        itemId={
          removePerson
            ? [removePerson.forenames, removePerson.surname].join(' ') ||
              removePerson.collarNumber ||
              removePerson.code
            : ''
        }
        onOk={handleRemove}
        onCancel={() => setRemovePerson(null)}
      />
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.ssi}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Fragment>
  );
}
