import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSetUrgentActionComplete() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code, isComplete }) => {
      return briefingApi
        .patch(`urgent-actions/${code}/complete`, {
          json: isComplete,
        })
        .json();
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['urgentActions'] }),
  });
}
