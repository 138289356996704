import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useAverageOccupantsByHour(match) {
  const queryKey = ['averageOccupantsByHour', match];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        { $match: match },
        {
          $project: {
            _id: false,
            startTime: { $max: ['$startTime', match.endTime.$gte] },
            endTime: { $min: ['$endTime', match.startTime.$lt] },
          },
        },
        {
          $set: {
            intervalTime: {
              $map: {
                input: {
                  $range: [
                    {
                      $divide: [
                        {
                          $toLong: {
                            $dateTrunc: {
                              date: '$startTime',
                              unit: 'hour',
                              timezone: 'Europe/London',
                            },
                          },
                        },
                        1000,
                      ],
                    },
                    {
                      $divide: [
                        {
                          $toLong: {
                            $dateTrunc: { date: '$endTime', unit: 'second' },
                          },
                        },
                        1000,
                      ],
                    },
                    3600,
                  ],
                },
                as: 'timestamp',
                in: { $toDate: { $multiply: ['$$timestamp', 1000] } },
              },
            },
          },
        },
        { $unwind: '$intervalTime' },
        {
          $project: {
            intervalTime: true,
            measure: {
              $divide: [
                {
                  $dateDiff: {
                    startDate: { $max: ['$startTime', '$intervalTime'] },
                    endDate: {
                      $min: [
                        '$endTime',
                        {
                          $dateAdd: {
                            startDate: '$intervalTime',
                            amount: 1,
                            unit: 'hour',
                          },
                        },
                      ],
                    },
                    unit: 'second',
                  },
                },
                3600,
              ],
            },
          },
        },
        {
          $group: {
            _id: '$intervalTime',
            measure: { $sum: '$measure' },
          },
        },
        {
          $group: {
            _id: { $hour: { date: '$_id', timezone: 'Europe/London' } },
            average: { $avg: '$measure' },
          },
        },
        {
          $project: {
            _id: false,
            hour: '$_id',
            average: true,
          },
        },
        { $sort: { hour: 1 } },
      ];

      log('Read', 'Average Occupants by Hour', match);

      return api.post('pipeline/intersections', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(match),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
