import { DetailsPanel, RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle, useOptionLookup } from '@/hooks';
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

export function StopCheck({
  id,
  background,
  color,
  reportedTime,
  startTime,
  endTime,
  statusCode,
  summary,
  typeCode,
}) {
  useDocumentTitle(['IR3', 'Stop Checks', id].filter(Boolean).join(' | '));
  const stopCheckTypes = useOptionLookup('stopCheckType');
  const occurrenceStatuses = useOptionLookup('occurrenceStatus');

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={
          <Badge>
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="crimes" />
            </Avatar>
          </Badge>
        }
        title={id}
        subheader={stopCheckTypes[typeCode]}
        action={<Chip label={occurrenceStatuses[statusCode] ?? statusCode} />}
      />
      <Stack spacing={2} sx={{ p: 2, pt: 0 }}>
        <Typography variant="body2">{summary}</Typography>
        <DetailsPanel
          entries={[
            {
              label: 'Reported',
              value: reportedTime
                ? format(reportedTime, 'dd/MM/yyyy H:mm')
                : '',
            },
            {
              label: 'Occurred',
              value: [
                startTime && format(startTime, 'dd/MM/yyyy H:mm'),
                endTime && format(endTime, 'dd/MM/yyyy H:mm'),
              ]
                .filter(Boolean)
                .join(' - '),
            },
          ]}
        />
      </Stack>
    </Card>
  );
}
