import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertSharedMissingPerson() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code, ...json }) => {
      if (code) {
        return briefingApi
          .patch(`shared-missing-persons/${code}`, { json })
          .json();
      } else {
        return briefingApi.post('shared-missing-persons', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['sharedMissingPersons'] }),
  });
}
