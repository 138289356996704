import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useBriefs() {
  return useQuery({
    queryKey: ['briefs'],
    queryFn: () => {
      return briefingApi.get('briefs').json();
    },
    placeholderData: (previousData) => previousData ?? [],
    staleTime: 1000 * 60 * 60,
  });
}
