import { Avatar, Tooltip } from '@mui/material';
import { getRiskProps } from '../utils';

export function RiskAvatar({ risk }) {
  const { label, color, Icon } = getRiskProps(risk);

  return (
    <Tooltip title={`${label} Risk`}>
      <Avatar
        sx={{
          width: 24,
          height: 24,
          fontSize: 16,
          bgcolor: color,
          color: (theme) => theme.palette.getContrastText(color),
        }}
      >
        <Icon sx={{ fontSize: 'inherit' }} />
      </Avatar>
    </Tooltip>
  );
}
