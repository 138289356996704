import {
  useAddNestedOption,
  useDeleteNestedOption,
  useDeleteOption,
  useInsertOption,
  useOptions,
} from '@/hooks';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';

export function TypedOptionsTable({ name, title }) {
  const [addOption, setAddOption] = useState(null);
  const [error, setError] = useState(null);
  const { data, isFetching, isLoading } = useOptions(name);
  const { mutate: insertOption } = useInsertOption();
  const { mutate: deleteOption } = useDeleteOption();
  const { mutate: addNestedOption } = useAddNestedOption();
  const { mutate: deleteNestedOption } = useDeleteNestedOption();

  const handleDeleteClick = (value, typeValue) => () => {
    if (typeValue) {
      deleteNestedOption({
        name,
        value: typeValue,
        valueToDelete: value,
      });
    } else {
      deleteOption({ name, value });
    }
  };

  function handleAddClick() {
    setAddOption({ name, value: '', label: '', type: '' });
  }

  function handleCancel() {
    setAddOption(null);
  }

  function handleSave() {
    if (!addOption.value || !addOption.label) {
      setError({
        value: !addOption.value && 'Required',
        label: !addOption.label && 'Required',
      });
      return;
    }

    if (addOption.type) {
      if (
        data
          .find((option) => option.value === addOption.type)
          .values?.some((option) => option.value === addOption.value)
      ) {
        setError({ value: 'Exists' });
        return;
      }

      addNestedOption({
        name,
        value: addOption.type,
        option: { value: addOption.value, label: addOption.label },
      });
    } else {
      if (data.some((option) => option.value === addOption.value)) {
        setError({ value: 'Exists' });
        return;
      }
      insertOption(addOption);
    }
    setAddOption(null);
    setError(null);
  }

  const handleAddOptionChange = (key) => (event) => {
    setAddOption((option) => ({ ...option, [key]: event.target.value }));
  };

  function renderTopToolbarCustomActions() {
    return <Typography variant="h6">{title}</Typography>;
  }

  function renderToolbarInternalActions() {
    return (
      <Tooltip title="Add">
        <IconButton size="small" onClick={handleAddClick}>
          <Avatar
            sx={{
              color: 'secondary.contrastText',
              bgcolor: 'secondary.main',
              width: 24,
              height: 24,
              fontSize: 16,
            }}
          >
            <AddIcon fontSize="inherit" />
          </Avatar>
        </IconButton>
      </Tooltip>
    );
  }

  function renderRowActions({ row }) {
    return (
      <Tooltip title="Delete" placement="left">
        <IconButton
          onClick={handleDeleteClick(row.id, row.parentId)}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  function getRowId(original) {
    return original.value;
  }

  function getSubRows(original) {
    return original.values;
  }

  const table = useMaterialReactTable({
    data,
    columns: [
      { accessorKey: 'value', header: 'Code' },
      { accessorKey: 'label', header: 'Name' },
    ],
    defaultColumn: { size: 0 },
    initialState: {
      density: 'compact',
      sorting: [{ id: 'value' }],
    },
    state: {
      isLoading: isFetching || isLoading,
    },
    enableBottomToolbar: false,
    enableColumnActions: false,
    enablePagination: false,
    enableExpanding: true,
    getRowId,
    getSubRows,
    renderTopToolbarCustomActions,
    renderToolbarInternalActions,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions,
    displayColumnDefOptions: { 'mrt-row-actions': { header: '', size: 0 } },
    muiTableContainerProps: { sx: { height: 'calc(100vh - 170px)' } },
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
      <Dialog open={!!addOption} onClose={handleCancel}>
        <Stack spacing={1} sx={{ p: 2, pb: 0 }}>
          <Typography variant="h6">Add to {title}</Typography>
          <Typography variant="caption" color="textSecondary">
            Leave the Type field blank if you wish to add a new type
          </Typography>
          <TextField
            select
            size="small"
            label="Type"
            value={addOption?.type ?? ''}
            onChange={handleAddOptionChange('type')}
          >
            {data.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
          <TextField
            size="small"
            label="Code"
            value={addOption?.value ?? ''}
            onChange={handleAddOptionChange('value')}
            error={!!error?.value}
            helperText={error?.value}
          />
          <TextField
            size="small"
            label="Name"
            value={addOption?.label ?? ''}
            onChange={handleAddOptionChange('label')}
            error={!!error?.label}
            helperText={error?.label}
          />
        </Stack>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
