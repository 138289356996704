import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';

export function SectionSwitches({ sections, value, onChange }) {
  function handleChange(key) {
    return function (event) {
      if (event.target.checked) {
        onChange(value.filter((entry) => entry !== key));
      } else {
        onChange(value.concat(key));
      }
    };
  }

  return (
    <Box sx={{ overflowY: 'scroll' }}>
      <FormGroup>
        {Object.entries(sections || {}).map(([key, { title, required }]) => (
          <FormControlLabel
            key={key}
            control={
              <Switch
                checked={!value.includes(key)}
                onChange={handleChange(key)}
                disabled={required}
              />
            }
            label={title}
          />
        ))}
      </FormGroup>
    </Box>
  );
}
