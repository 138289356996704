import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSubject(type, personId, defaultValue = {}) {
  return useQuery({
    queryKey: ['subjects', type, personId, defaultValue],
    queryFn: async () => {
      const existingSubject = await briefingApi
        .get(`subjects/${type}/${personId}`)
        .json();

      if (existingSubject?.code) {
        return existingSubject;
      } else {
        return defaultValue;
      }
    },
    enabled: !!type && !!personId,
    staleTime: 1000 * 60 * 60,
  });
}
