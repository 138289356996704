import { Box, Button, Input, Popover, Stack } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useState } from 'react';

export function UrgentActionUpdatePopover({ isOpen, anchorEl, onClose }) {
  const [message, setMessage] = useState('');

  function handleSaveClick() {
    onClose(message);
    setMessage('');
  }

  function handleChange(event) {
    setMessage(event.target.value);
  }

  function handleClose() {
    setMessage('');
    onClose();
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[800]
              : yellow[200],
          width: 320,
        })}
      >
        <Stack sx={{ p: 1 }}>
          <Input
            value={message}
            onChange={handleChange}
            disableUnderline
            placeholder="Add your update..."
            multiline
            rows={10}
            sx={{ p: 1 }}
          />
          <Stack direction="row" spacing={1}>
            <Box sx={{ flexGrow: 1 }} />
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveClick}>Save</Button>
          </Stack>
        </Stack>
      </Box>
    </Popover>
  );
}
