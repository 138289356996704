import { WeeklySchedulePicker } from '@/components/controls';
import { parseIfJSON } from '@/utils';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';
import { Status } from './Status';

export function ObjectivePopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const startTime = new Date(properties.startTime);
  const endTime = new Date(properties.endTime);
  const microbeats = parseIfJSON(properties.microbeats);
  const requirement = `Requires ${properties.requiredVisits ?? 0} visits of ${
    properties.complianceSeconds ?? 0 / 60
  } minutes ${properties.requiredFrequency ?? ''}`;
  const schedule = parseIfJSON(properties.schedule);

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
      maxWidth="448px"
    >
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {properties.title}
          </Typography>
          {properties.occurrenceNumber && (
            <Chip
              size="small"
              label={properties.occurrenceNumber}
              sx={{
                bgcolor: deepOrange[500],
                color: '#fff',
              }}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Typography variant="caption" color="textSecondary">
            {`${format(startTime, 'dd/MM/yyyy')} - ${format(
              endTime,
              'dd/MM/yyyy',
            )}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {requirement}
          </Typography>
        </Stack>
        <Status status={properties.status} />
        <Box
          sx={{
            maxHeight: 400,
            overflowY: 'scroll',
            '& img': { maxWidth: ['100%', '!important'] },
          }}
          dangerouslySetInnerHTML={{ __html: properties.contentHTML }}
        />
        <Stack direction="row" spacing={0.5} sx={{ overflow: 'scroll' }}>
          {(microbeats ?? []).map((microbeat) => (
            <Chip
              size="small"
              key={microbeat}
              label={microbeat}
              sx={{ fontSize: '.6rem', height: 18 }}
            />
          ))}
        </Stack>
        {schedule && (
          <WeeklySchedulePicker value={schedule} disabled scale={0.535} />
        )}
      </Stack>
    </Popup>
  );
}
