import { useTeamVehicles } from '@/hooks';
import { getBackgroundColor, getHoverBackgroundColor, spin } from '@/utils';
import { useTheme } from '@emotion/react';
import {
  Autorenew as AutorenewIcon,
  Done as DoneIcon,
} from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';

const lastCheckColor = (time, theme) => {
  if (!time) {
    return theme.palette.common.grey;
  }

  const now = new Date();
  const diff = now - time;
  const days = Math.floor(diff / 86400000);

  if (days < 8) {
    return theme.palette.success.main;
  } else if (days < 15) {
    return theme.palette.warning.main;
  } else {
    return theme.palette.error.main;
  }
};

const columnsFn = (theme) => [
  {
    accessorKey: 'keyNumber',
    header: 'Key',
    size: 0,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  { accessorKey: 'registrationNumber', header: 'Registration', size: 0 },
  { accessorKey: 'fleetNumber', header: 'Fleet Number', size: 0 },
  {
    header: 'Class',
    size: 0,
    key: 'class',
    id: 'class',
    accessorFn: ({ make, model, colour }) =>
      [colour, make, model].filter(Boolean).join(' '),
  },
  {
    accessorKey: 'driver',
    header: 'Last/Current Driver',
    size: 0,
    Cell: ({ cell }) => (
      <span
        style={{
          color: cell.getValue()?.isCurrent
            ? theme.palette.primary.main
            : undefined,
        }}
      >
        {[
          cell.getValue()?.collarNumber,
          cell.getValue()?.forenames,
          cell.getValue()?.surname,
        ]
          .filter(Boolean)
          .join(' ')}
      </span>
    ),
  },
  {
    accessorKey: 'lastCheckTime',
    header: 'Last Weekly Check',
    size: 0,
    Cell: ({ cell }) => (
      <span style={{ color: lastCheckColor(cell.getValue(), theme) }}>
        {cell.getValue()
          ? format(cell.getValue(), 'dd/MM/yyyy')
          : cell.getValue()}
      </span>
    ),
  },
];

export function Vehicles({ groupCodes, onComplete }) {
  const {
    data: vehicles,
    isLoading,
    isFetching,
    refetch,
  } = useTeamVehicles({ groups: groupCodes });
  const theme = useTheme();
  const columns = useMemo(() => columnsFn(theme), [theme]);

  function getRowStyle(status) {
    switch (status) {
      case 'scheduled':
        return {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
              ),
          },
          '&:last-child td, &:last-child th': { border: 0 },
        };
      case 'workshop':
        return {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
              ),
          },
          '&:last-child td, &:last-child th': { border: 0 },
        };
    }
  }

  function handleCompleteClick() {
    onComplete();
  }

  function handleRefreshClick() {
    refetch();
  }

  function renderTopToolbarCustomActions() {
    return (
      <Stack direction="row" spacing={1} alignItems="center" flex={1}>
        <Typography variant="h6" sx={{ pl: 1 }}>
          Vehicles
        </Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Tooltip title="Refresh" placement="bottom">
            <Box component="span">
              <IconButton
                onClick={handleRefreshClick}
                disabled={isFetching || isLoading}
              >
                <AutorenewIcon
                  fontSize="inherit"
                  sx={
                    isLoading || isFetching
                      ? { animation: `${spin} 2s linear infinite` }
                      : undefined
                  }
                />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Stack>
    );
  }

  const table = useMaterialReactTable({
    columns,
    data: vehicles,
    initialState: { density: 'compact' },
    state: { isLoading: isLoading || isFetching },
    renderTopToolbarCustomActions,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableBottomToolbar: false,
    muiTablePaperProps: { variant: 'outlined', elevation: 0 },
    muiTableBodyRowProps: ({ row }) => ({
      hover: !row.original.status,
      sx: getRowStyle(row.original.status),
    }),
    muiSearchTextFieldProps: { variant: 'outlined', size: 'small' },
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 108px)' } },
  });

  return <MaterialReactTable table={table} />;
}
