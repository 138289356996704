import {
  Autocomplete,
  Chip,
  List,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { forwardRef, useRef } from 'react';

const defaultValue = [];
const labelValueFilter = createFilterOptions({
  stringify: (option) => option.value,
});

function getLabel(option) {
  let label = option?.label ?? option?.altLabel ?? option ?? '';

  if (label === '*Any' || label === '*None') {
    label = label.substring(1);
  }

  return label.toString();
}

const VirtualisedListbox = forwardRef(function VirtualisedListbox(
  { children: options, role, ...props },
  ref,
) {
  const scrollRef = useRef(null);
  const virtualizer = useVirtualizer({
    count: options.length,
    estimateSize: () => 40, //* NOTE: You may have to play around with this value.
    getScrollElement: () => scrollRef.current,
  });

  return (
    <div ref={ref}>
      <List
        {...props}
        role={role}
        ref={scrollRef}
        component="div"
        sx={{
          position: 'relative',
          height: virtualizer.getTotalSize(),
        }}
      >
        {virtualizer.getVirtualItems().map((item) => {
          const [{ key, ...props }, option] = options[item.index];

          return (
            <Typography
              component="li"
              key={key}
              {...props}
              noWrap
              sx={{
                py: 0.5,
                width: '100%',
                position: 'absolute',
                transform: `translateY(${item.start}px)`,
              }}
            >
              {option.label}
            </Typography>
          );
        })}
      </List>
    </div>
  );
});

export function SelectMultiple({
  value,
  suggestions = [],
  onChange,
  disabled,
  typeIcons,
  className,
  styles = {},
  filterOptions,
  labelValue = false,
  anyOption = false,
  noneOption = false,
  meta = {},
  showNonExistantAsError = false,
  ...props
}) {
  const theme = useTheme();
  const divider = {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
  };

  styles = {
    ...styles,
    divider,
  };

  if (!filterOptions && labelValue) {
    filterOptions = labelValueFilter;
  }

  // make sure it's just a list of strings as the change, so if the
  // changed item (last one) is an object get its value
  function handleChange(event, value) {
    const [selection, ...existingValues] = value.slice().reverse();

    if (typeof selection === 'object') {
      onChange([...existingValues.reverse(), selection.value]);
    } else {
      onChange(value);
    }
  }

  const safeValue =
    // !! disallows "" and defaultValue isn't a new [] every time
    value !== undefined && value !== ''
      ? Array.isArray(value)
        ? value
        : [value]
      : defaultValue;

  // don't show the none option if any is selected and vice versa
  noneOption = noneOption && !safeValue.includes('*Any');
  anyOption = anyOption && !safeValue.includes('*None');

  const newSuggestions = Array.from(new Set(suggestions.map((s) => s.value)))
    .map((value) => suggestions.find((s) => s.value === value))
    .filter((s) => !['*Any', '*None'].includes(s.value));

  // *Any and *None must always appear at the top, so any real-life
  // values that begin with e.g. #$%() don't get accidentally sorted
  // above Any/None because they have a lower ASCII value than '*'
  const options = [
    ...[
      anyOption && {
        value: '*Any',
        label: '*Any',
        className: noneOption ? undefined : 'divider',
      },
      noneOption && {
        value: '*None',
        label: '*None',
        className: 'divider',
      },
    ].filter(Boolean),
    ...newSuggestions.sort((a, b) => {
      if (a.type === b.type) {
        return a.label.localeCompare(b.label);
      }
      return a.type.localeCompare(b.type);
    }),
  ];

  return (
    <Autocomplete
      size="small"
      fullWidth
      // {...props}
      multiple
      ListboxComponent={VirtualisedListbox}
      ListboxProps={{ styles, options }}
      className={className}
      value={safeValue}
      disabled={disabled}
      onChange={handleChange}
      options={options}
      isOptionEqualToValue={(option, selected) => option.value === selected}
      // if custom filtering isn't applied autocomplete will attempt to do
      // it's own filtering which does option.toLowerCase and if option is
      // an object it will crash
      // getOptionLabel={!filterOptions ? getLabel : undefined}
      getOptionLabel={getLabel}
      renderOption={(props, option) => [props, option]}
      filterOptions={filterOptions}
      //{...optionRendering}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          {...props}
          helperText={meta.error ?? meta.submitError}
          error={meta.invalid}
        />
      )}
      renderTags={(value, getTagProps) =>
        safeValue.map((option, index) => {
          const { key, ...props } = getTagProps({ index });
          const suggestion = newSuggestions.find(
            (suggestion) => suggestion.value === option,
          );

          const exists =
            !showNonExistantAsError ||
            newSuggestions.find(
              (s) => (s.value ?? s) === (option.value ?? option),
            );

          let color = undefined;
          if (['*Any', '*None'].includes(option)) {
            color = 'primary';
          } else if (!exists) {
            color = 'error';
          }

          return (
            <Chip
              key={key}
              size="small"
              color={color}
              label={getLabel(suggestion || option)}
              {...props}
              icon={typeIcons && suggestion && typeIcons[suggestion.type]}
              style={styles[suggestion?.className]}
            />
          );
        })
      }
    />
  );
}
