import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertWatch() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code, groupAncestors: _, ...json }) => {
      return code
        ? briefingApi.patch(`watches/${code}`, { json }).json()
        : briefingApi.post('watches', { json }).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['watches'] }),
  });
}
