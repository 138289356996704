import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useLocations({ type, homeStations = false } = {}) {
  return useQuery({
    queryKey: ['locations', type, homeStations],
    queryFn: () => {
      const json = [
        type && { $match: { type } },
        ...(homeStations
          ? [
              {
                $lookup: {
                  from: 'options',
                  let: { type: '$type' },
                  pipeline: [
                    {
                      $match: {
                        name: 'locationType',
                        $expr: { $eq: ['$$type', '$value'] },
                      },
                    },
                    { $project: { _id: false, isHomeStation: true } },
                  ],
                  as: 'locationType',
                },
              },
              { $unwind: '$locationType' },
              { $match: { 'locationType.isHomeStation': true } },
            ]
          : []),
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
          },
        },
        {
          $project: {
            code: true,
            name: true,
            type: true,
            picture: true,
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            searchString: {
              $toLower: {
                $concat: [
                  { $ifNull: ['$name', ''] },
                  '+',
                  { $ifNull: ['$code', ''] },
                ],
              },
            },
          },
        },
        { $sort: { name: 1 } },
      ].filter(Boolean);

      log('Read', 'Locations', { type, homeStations });

      return api.post('pipeline/locations', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
