import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteUrgentAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code) => {
      if (code) {
        return briefingApi.delete(`urgent-actions/${code}`).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['urgentActions'] }),
  });
}
