import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUser(username) {
  return useQuery({
    queryKey: ['users', username],
    queryFn: () => {
      return api.get(`users/${username}`).json();
    },
    enabled: !!username,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60,
  });
}
